import type { SubscribeButtonSection } from '@domains/ad/types/subscribeButtonSection';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { SavedAdsContext } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons/faHeart';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons/faHeart';
import type { FC } from 'react';
import { useContext } from 'react';

import { StyledButton, SubscribeButtonIcon } from './AdSubscribeButton.theme';

interface Props {
    adId: number;
    section: SubscribeButtonSection;
    className?: string;
    dataCySuffix?: string;
    handleSubscribeButtonClick: (section: SubscribeButtonSection) => void;
}

export const AdSubscribeButton: FC<Props> = ({ adId, className, handleSubscribeButtonClick, section }) => {
    const [t] = useTranslations();
    const { adsIdInUpdateState, checkIfAdIsSaved } = useContext(SavedAdsContext);
    const isSubscribed = checkIfAdIsSaved(adId);
    const shouldDisableButton = adsIdInUpdateState.has(adId);

    return (
        <StyledButton
            data-cy="ad-subscribe-button-sidebar.subscribe"
            data-cy-subscribed={isSubscribed}
            className={className}
            variant={ButtonVariant.Ghost}
            onClick={(): void => handleSubscribeButtonClick(section)}
            disabled={shouldDisableButton}
        >
            <SubscribeButtonIcon icon={isSubscribed ? faHeartSolid : faHeartRegular} />
            {t(
                isSubscribed
                    ? 'frontend.atoms.ad-subscribe-button.saved-advert'
                    : 'frontend.atoms.ad-subscribe-button.save-advert',
            )}
        </StyledButton>
    );
};

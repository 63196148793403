import { AdSectionHeading } from '@domains/ad/components/AdSectionHeading/AdSectionHeading';
import styled from '@emotion/styled';

export const AdInvestmentUnitsSection = styled.section`
    position: relative;
`;

export const NewProjectPageAdSectionHeading = styled(AdSectionHeading)`
    padding-top: 0;

    &::before {
        padding-bottom: 0;
    }
`;

export const MONETIZATION_FEATURE = {
    academy: 'ACADEMY',
    adHomepage: 'AD_HOMEPAGE',
    adStatistics: 'AD_STATISTICS',
    additionalContactForms: 'ADDITIONAL_CONTACT_FORMS_AD_PAGE',
    advancedGallery: 'ADVANCED_PICTURE_GALLERY_AD_PAGE',
    agencyWebPage: 'AGENCY_WEB_PAGE',
    aiMatchingAndSmsCampaigns: 'AI_MATCHING_AND_SMS_CAMPAIGNS',
    alfavox: 'ALFAVOX',
    bannerDiscount: 'BANNER_DISCOUNT',
    branding: 'BRANDING',
    businessUserPayment: 'BUSINESSUSER_PAYMENT',
    cenatorium: 'CENATORIUM',
    crmIntegration: 'CRM_INTEGRATION',
    dedicatedCs: 'DEDICATED_CS',
    dynamicRemarketing: 'DYNAMIC_REMARKETING_OTODOM_ADSPACE',
    exclusiveOffer: 'EXCLUSIVE_OFFER',
    exportOlx: 'EXPORT_OLX',
    header: 'HEADER',
    improvedInvestmentPage: 'IMPROVED_INVESTMENT_PAGE',
    incentivation: 'INCENTIVATION',
    lexnonstop: 'LEXNONSTOP',
    logoAdPage: 'LOGO_AD_PAGE',
    logoSearchPage: 'LOGO_SEARCH_PAGE',
    mailingTool: 'MAILING_TOOL',
    megabump: 'MEGABUMP',
    n3destate: 'N3DESTATE',
    olxBundle: 'OLX_BUNDLE',
    olxPushup: 'OLX_PUSHUP',
    olxTopads: 'OLX_TOPADS',
    packet: 'PACKET',
    paidForPost: 'PAID_FOR_POST',
    pdfReports: 'PDF_REPORTS',
    predefinedMessages: 'PREDEFINED_MESSAGES_CONTACT_FORM',
    projectRed: 'PROJECT_RED',
    promotionsRecommendations: 'PROMOTIONS_RECOMMENDATIONS',
    pushup: 'PUSHUP',
    simplrent: 'SIMPLRENT',
    spotbrowser: 'SPOTBROWSER',
    statistics: 'STATISTICS',
    stockAndPricingOptimization: 'STOCK_AND_PRICING_OPTIMISATION',
    suggestPriceFrom: 'SUGGEST_PRICE_FORM',
    topads: 'TOPADS',
    topupaccount: 'TOPUPACCOUNT',
    transactionalMap: 'TRANSACTIONAL_MAP',
    vasPlannerWithAutomaticScheduling: 'VAS_PLANNER_WITH_AUTOMATIC_SCHEDULING',
    vasScheduling: 'VAS_SCHEDULING',
    virtualPropertyView: 'VIRTUAL_PROPERTY_VIEW',
    webinarsAndTrainingsAccess: 'WEBINARS_AND_TRAININGS_ACCESS',
    contactPersonalisation: 'CONTACT_PERSONALISATION',
} as const;

export type MonetizationFeature = ObjectValues<typeof MONETIZATION_FEATURE>;

import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';
import { SIZE } from '@lib/styles/partials/typography';

export const Container = styled.section`
    position: relative;
    z-index: ${LAYER.x0};
    padding-bottom: 16px;
    color: ${({ theme }): string => theme.deprecated.domains.ad.adDescription.color};
    font-size: ${SIZE.p2};
    line-height: 24px;
`;

export const ProjectPageDescriptionWrapper = styled.div`
    overflow-wrap: break-word;
`;

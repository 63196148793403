import type { Locale } from '@lib/i18n/types/locale';

interface Params {
    pastDate: Date;
    from: Date;
    lang: Locale;
}

const getDiffInMonths = (dateFrom: Date, dateTo: Date): number =>
    dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());

export const getRelativeTime = async ({ pastDate, from, lang }: Params): Promise<string> => {
    try {
        const rtf = new Intl.RelativeTimeFormat(lang, { style: 'long' });

        const diffInMs = from.getTime() - pastDate.getTime();

        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        const day = hour * 24;
        // we made an assumption that average month is 30 days
        // if diff is 1-29 days we display diff in days, if it is 30 and more we display it in months
        const month = day * 30;

        let relativeTime;

        if (diffInMs < minute) {
            // display time in seconds
            const diffInSeconds = Math.floor(diffInMs / second);
            relativeTime = rtf.format(-diffInSeconds, 'second');
        } else if (diffInMs < hour) {
            // display time in minutes
            const diffInMinutes = Math.floor(diffInMs / minute);
            relativeTime = rtf.format(-diffInMinutes, 'minute');
        } else if (diffInMs < day) {
            // display time in hours
            const diffInHours = Math.floor(diffInMs / hour);
            relativeTime = rtf.format(-diffInHours, 'hour');
        } else if (diffInMs < month) {
            // display time in days
            const diffInDays = Math.floor(diffInMs / day);
            relativeTime = rtf.format(-diffInDays, 'day');
        } else {
            // display time in months or years
            const diffInMonths = getDiffInMonths(pastDate, from);
            const diffInYears = Math.floor(diffInMonths / 12);
            relativeTime = diffInYears > 0 ? rtf.format(-diffInYears, 'year') : rtf.format(-diffInMonths, 'month');
        }

        return relativeTime;
    } catch {
        const { default: dayjs } = await import('dayjs');
        const { default: relativeTime } = await import('dayjs/plugin/relativeTime');
        await import(/* webpackInclude: /(pl|pt|ro|ru|uk|en)\.js$/ */ `dayjs/locale/${lang}.js`);
        dayjs.extend(relativeTime);
        dayjs.locale(lang);

        return dayjs(pastDate).from(from);
    }
};

import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE } from '@lib/styles/partials/typography';

export const Content = styled.div`
    flex: 0 0 50%;
    padding: 16px 0;
    text-align: center;

    @media (min-width: ${BREAKPOINT.md}) {
        flex: 0 0 25%;
        padding: 16px;
    }
`;

export const StyledIcon = styled(Icon)`
    color: ${({ theme }): string => theme.deprecated.domains.ad.projectTopInformationBox.icon.color};
    font-size: ${SIZE.h2};
`;

export const Title = styled.div`
    margin: 24px 0 8px;
    font-size: ${SIZE.p3};

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.h6};
    }
`;

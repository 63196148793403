import { SITE_CONFIG } from '@config/siteConfig';
import { getFormattedPrice } from '@domains/shared/helpers/getFormattedPrice';
import { getCurrencyIsoCode } from '@domains/shared/helpers/price/getCurrencyIsoCode';
import { getDiscountedPrice } from '@domains/shared/helpers/price/getDiscountedPrice';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import type { SpecialOffer } from '@type/ad/specialOffer';
import type { Currency } from '@type/pricing/currency';
import { checkIsCurrency } from '@type/pricing/currency';

interface Arguments {
    price: AdvertCharacteristic;
    specialOffer: SpecialOffer | null;
    isInvestment: boolean;
}

interface CalculatedPrice {
    discountedPrice: string | null;
    priceBeforeDiscount: string;
}

const getValidCurrency = (currency: string): Currency => {
    if (checkIsCurrency(currency)) {
        return currency;
    }

    return getCurrencyIsoCode(SITE_CONFIG.defaultUnits.currency) as Currency;
};

export const getAdPrice = ({ price, specialOffer, isInvestment }: Arguments): CalculatedPrice => {
    const {
        defaultUnits: { area },
    } = SITE_CONFIG;
    const { value, currency, localizedValue } = price;

    const discountedPercentage = specialOffer?.discountValue;
    const validCurrency = getValidCurrency(currency);

    const discountedPrice =
        value && discountedPercentage
            ? getFormattedPrice({
                  value: getDiscountedPrice(Number(value), discountedPercentage),
                  currency: validCurrency,
              })
            : null;

    const discountedPriceForInvestment = discountedPrice ? `${discountedPrice}/${area}` : null;

    const priceBeforeDiscount =
        specialOffer && specialOffer.minPriceLastDays
            ? `${getFormattedPrice({
                  value: specialOffer.minPriceLastDays,
                  currency: validCurrency,
              })}`
            : localizedValue;

    const priceBeforeDiscountForInvestment = `${priceBeforeDiscount}/${area}`;

    return {
        discountedPrice: isInvestment ? discountedPriceForInvestment : discountedPrice,
        priceBeforeDiscount: isInvestment ? priceBeforeDiscountForInvestment : priceBeforeDiscount,
    };
};

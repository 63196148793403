import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FC } from 'react';

import { Banner, Icon, Label } from './AdLeaderBanner.theme';
import leaderIcon from './assets/leader-logo.svg?url';

interface Props {
    rewardYear: string;
}

export const AdLeaderBanner: FC<Props> = ({ rewardYear }) => {
    const [t] = useTranslations();

    return (
        <Banner aria-label={t('frontend.ad.re-leader-banner.component-label')}>
            <Icon src={leaderIcon} alt={t('frontend.ad.re-leader-banner.icon')} />
            <span>
                {`${t('frontend.ad.re-leader-banner.title')} `}
                <Label>{t('frontend.ad.re-leader-banner.label', { year: rewardYear })}</Label>
            </span>
        </Banner>
    );
};

import { useOpenDateFormatter } from '@domains/ad/hooks/useOpenDateFormatter';
import { Button } from '@domains/shared/components/Button/Button';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import { useTracking } from '@lib/tracking/useTracking';
import type { OpenDay } from '@type/ad/openDay';
import type { JSX } from 'react';
import { memo, useState } from 'react';

import { Container, DateIcon, DateTimeContainer, DateTimeItem, Heading, MainIcon } from './OpenDayNotification.theme';

interface Props {
    openDay: OpenDay | null;
    isDateInitiallyHidden?: boolean;
}

const OpenDayNotificationBase = ({ openDay, isDateInitiallyHidden = false }: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const [isDateHidden, setIsDateHidden] = useState(isDateInitiallyHidden);
    const { trackEvent } = useTracking();
    const formatOpenDay = useOpenDateFormatter();

    const showDate = (): void => {
        setIsDateHidden(false);

        trackEvent('open_day');
    };

    const { date: rawDate, timeFrom, timeTo } = openDay || {};

    if (!rawDate) {
        // The open day is null when it hasn't been set or its time has passed
        return null;
    }

    const formattedDate = formatOpenDay(rawDate, true);

    return (
        <Container aria-labelledby="open-day-section-title">
            <MainIcon icon={faDoorOpen} size="2x" />
            <Heading id="open-day-section-title">{t('frontend.ad.remote-service.open-day')}</Heading>
            {isDateHidden ? (
                <Button variant={ButtonVariant.Ghost} onClick={showDate}>
                    {t('frontend.ad.remote-service.open-day-show')}
                </Button>
            ) : (
                <DateTimeContainer dateTime={`${rawDate}T${timeFrom}`}>
                    <DateTimeItem>
                        <DateIcon icon={faCalendar} /> {formattedDate}
                    </DateTimeItem>
                    <DateTimeItem>
                        <DateIcon icon={faClock} /> {timeFrom} - {timeTo}
                    </DateTimeItem>
                </DateTimeContainer>
            )}
        </Container>
    );
};

export const OpenDayNotification = memo(OpenDayNotificationBase);

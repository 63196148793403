import { Badge } from '@domains/shared/components/dataDisplay/Badge/Badge';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

export const StyledBadge = styled(Badge)`
    width: max-content;
    height: max-content;

    svg {
        margin-right: 8px;
        vertical-align: middle;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        margin-top: 0;
        margin-left: 16px;
        vertical-align: middle;
        white-space: nowrap;
    }
`;

import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

/* font-size: used to set the main icon size correctly (2x) */
export const Container = styled.section`
    display: grid;
    grid: min-content min-content / 80px 1fr;
    grid-gap: 4px 16px;
    align-items: end;
    max-width: 520px;
    min-height: 56px;
    margin: 32px 0 20px;
    padding: 8px 8px 8px 0;
    border-radius: 4px;
    background: radial-gradient(
        circle at 0,
        ${({ theme }): string => theme.deprecated.domains.ad.openDay.mainIcon.backgroundColor} 80px,
        ${({ theme }): string => theme.deprecated.domains.ad.openDay.backgroundColor} 81px
    );
    color: ${({ theme }): string => theme.deprecated.domains.ad.openDay.color};
    font-size: 13px;
    font-weight: ${WEIGHT.semibold};
    white-space: nowrap;

    @media (min-width: ${BREAKPOINT.sm}) {
        grid: 1fr / 80px 1fr 1fr;
        grid-gap: 0 24px;
        align-items: center;
    }
`;

export const Heading = styled.h2`
    margin: 0;
    padding: 0;
    font-size: ${SIZE.p2};
    font-weight: ${WEIGHT.bold};
`;

export const DateTimeContainer = styled.time`
    display: flex;
    flex-wrap: wrap;
    align-self: start;
    font-size: ${SIZE.p3};

    @media (min-width: ${BREAKPOINT.sm}) {
        flex-wrap: nowrap;
        align-self: inherit;
    }
`;

export const MainIcon = styled(Icon)`
    grid-row: 1 / 3;
    place-self: center center;
    color: ${({ theme }): string => theme.deprecated.domains.ad.openDay.mainIcon.color};
`;

export const DateIcon = styled(Icon)`
    margin: 0 8px 0 0;
    color: ${({ theme }): string => theme.deprecated.domains.ad.openDay.dateIcon.color};
`;

export const DateTimeItem = styled.span`
    margin: 0 16px 0 0;
`;

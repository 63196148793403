import { AD_CONFIG } from '@config/ad/adConfig';
import { AdAgencyBanner } from '@domains/ad/components/AdAgencyBanner/AdAgencyBanner';
import { AdAgencyBranding } from '@domains/ad/components/AdAgencyBranding/AdAgencyBranding';
import { AdBreadcrumbs } from '@domains/ad/components/AdBreadcrumbs/AdBreadcrumbs';
import { AdHeadMetadata } from '@domains/ad/components/AdHeadMetadata/AdHeadMetadata';
import { AdInvestmentUnits } from '@domains/ad/components/AdInvestmentUnits/AdInvestmentUnits';
import { AdLeaderBanner } from '@domains/ad/components/AdLeaderBanner/AdLeaderBanner';
import { AdMap } from '@domains/ad/components/AdMap/AdMap';
import type { OpenGalleryFunction } from '@domains/ad/components/AdMediaGallery/openGalleryRef';
import { AdMeta } from '@domains/ad/components/AdMeta/AdMeta';
import { AdReportAd } from '@domains/ad/components/AdReportAd/AdReportAd';
import { AdSectionHeading } from '@domains/ad/components/AdSectionHeading/AdSectionHeading';
import { AdSectionSecondaryHeading } from '@domains/ad/components/AdSectionSecondaryHeading/AdSectionSecondaryHeading';
import { ContactDetails } from '@domains/ad/components/ContactDetails/ContactDetails';
import { ObserveAdModal } from '@domains/ad/components/ObserveAdModal/ObserveAdModal';
import { ProjectMediaGallery } from '@domains/ad/components/ProjectPage/components/ProjectMediaGallery/ProjectMediaGallery';
import { ProjectPageDescription } from '@domains/ad/components/ProjectPage/components/ProjectPageDescription/ProjectPageDescription';
import { ScrollToAdsListButton } from '@domains/ad/components/ProjectPage/components/ScrollToAdsListButton/ScrollToAdsListButton';
import { VideoPlayer } from '@domains/ad/components/ProjectPage/components/VideoPlayer/VideoPlayer';
import { ProjectTopInformationBoxes } from '@domains/ad/components/ProjectTopInformationBoxes/ProjectTopInformationBoxes';
import { RecommendedAdsAdContentWrapper } from '@domains/ad/components/RecommendedAdsAdContentWrapper/RecommendedAdsAdContentWrapper';
import { SubscribeAdSection } from '@domains/ad/components/SubscribeAdSection/SubscribeAdSection';
import { ProjectAdditionalInformation } from '@domains/ad/components/Table/ProjectAdditionalInformation/ProjectAdditionalInformation';
import type { AdPageModalVisibility } from '@domains/ad/contexts/AdPageModalContext';
import { getContactFormAdvertProps } from '@domains/ad/helpers/getContactSectionAdvertData';
import { useAdOwner } from '@domains/ad/hooks/useAdOwner';
import { useLocationBreadcrumbs } from '@domains/ad/hooks/useLocationBreadcrumbs';
import { useRecommendedAdverts } from '@domains/ad/hooks/useRecommendedAdverts';
import { TwoColumnContentLayout } from '@domains/ad/layouts/TwoColumnContentLayout/TwoColumnContentLayout';
import type { Ad } from '@domains/ad/types/Ad';
import { Toaster } from '@domains/shared/components/Toast/Toaster';
import { SavedAdsContext } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { CurrentUserContext } from '@lib/currentUser/CurrentUserContext';
import { MainLayout } from '@lib/layouts/MainLayout/MainLayout';
import { ContentBackground } from '@lib/pages/contentBackground';
import { useTracking } from '@lib/tracking/useTracking';
import { MONETIZATION_FEATURE } from '@type/ad/monetizationFeature';
import { ContactPersonBannerSkeleton } from '@widgets/contactForm/ContactPersonBanner/ContactPersonBannerSkeleton';
import dynamic from 'next/dynamic';
import type { Dispatch, FC, RefObject, SetStateAction } from 'react';
import { useContext, useRef } from 'react';

import { ProjectAdHeader } from './ProjectAdHeader';

interface Props {
    ad: Ad;
    relativeUrl?: string;
    showBranding: boolean;
    isMobile: boolean;
    referer: string | null;
    openGalleryRef: RefObject<OpenGalleryFunction>;
    modalVisibility: AdPageModalVisibility;
    toggleModalState(newState: AdPageModalVisibility): void;
    isRedirectToLoginModalOpen: boolean;
    setIsRedirectToLoginModalOpen: Dispatch<SetStateAction<boolean>>;
}

const LazyAdPageStickyHeader = dynamic(() => import('@domains/ad/components/AdPageStickyHeader/AdPageStickyHeader'), {
    ssr: false,
});
const LazyContactPersonBanner = dynamic(() => import('@widgets/contactForm/ContactPersonBanner'), {
    loading: () => <ContactPersonBannerSkeleton />,
});
const LazyContactModal = dynamic(() => import('@widgets/contactForm/ContactModal'), {
    ssr: false,
});
const LazyMobileStickyFooter = dynamic(
    () => import('@domains/ad/components/ContactDetails/components/MobileStickyFooter/MobileStickyFooter'),
    {
        ssr: false,
    },
);

export const ProjectPage: FC<Props> = ({
    relativeUrl = '',
    ad,
    showBranding,
    isMobile,
    referer,
    openGalleryRef,
    modalVisibility,
    toggleModalState,
    isRedirectToLoginModalOpen,
    setIsRedirectToLoginModalOpen,
}) => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const { trackEvent } = useTracking();
    const { toggleSubscription } = useContext(SavedAdsContext);
    const currentUser = useContext(CurrentUserContext);
    const adsListRef = useRef<HTMLDivElement>(null);

    const { recommendedAdverts } = useRecommendedAdverts(ad.id);

    const handleSubscribeButtonClick = (): void => {
        if (currentUser !== null || !AD_CONFIG.adPageOptions.subscribeLoginPrompt) {
            toggleSubscription({ id: ad.id });
        } else {
            setIsRedirectToLoginModalOpen(true);
        }
    };

    const handleMobileStickyFooterClick = (): void => {
        toggleModalState('default');
        trackEvent('reply_message_form_click', {
            touch_point_button: 'mobile_footer',
        });
    };

    const breadcrumbs = useLocationBreadcrumbs(ad);
    const shouldDisplayUnits = ad.paginatedUnits?.items?.length > 0;
    const videoLink = ad.links?.videoUrl;
    const shouldShowMobileBranding = showBranding && isMobile;

    const enabledFeatures = new Set(ad.agency?.enabledFeatures || []);
    const shouldDisplayPredefinedContactMessages = enabledFeatures.has(MONETIZATION_FEATURE.predefinedMessages);
    const isAdvancedGallery = enabledFeatures.has(MONETIZATION_FEATURE.advancedGallery);

    const contactFormAdvertProps = getContactFormAdvertProps(ad);
    const contactPersonBannerAdvertProps = {
        advertId: ad.id,
        contactDetails: ad.contactDetails,
        ownerType: ad.owner.type,
        advertiserType: ad.advertiserType,
        agencyLogoUrl: ad.agency?.imageUrl,
    };

    const handlePhoneNumberClick = (): void => {
        if (ad.owner.type === 'agency') {
            toggleModalState('agency');
        }
    };

    const handleContactButtonClick = (): void => {
        toggleModalState('default');
    };

    const { adOwner } = useAdOwner(ad.owner.id);

    return (
        <>
            <AdHeadMetadata ad={ad} relativeUrl={relativeUrl} lang={lang} />
            <MainLayout hasStickyPageHeader={false} contentBackground={ContentBackground.none}>
                <Toaster />
                <LazyAdPageStickyHeader
                    ad={ad}
                    showBranding={showBranding && !isMobile}
                    handleSubscribeButtonClick={handleSubscribeButtonClick}
                    shouldDisplaySubscribeSection
                    sellerBadges={adOwner.sellerBadges}
                />
                {shouldShowMobileBranding && ad.agency ? (
                    <AdAgencyBranding
                        agency={ad.agency}
                        adCategory={ad.adCategory}
                        sellerBadges={adOwner.sellerBadges}
                    />
                ) : null}
                <TwoColumnContentLayout
                    showBrandingMargin={showBranding && !isMobile}
                    breadcrumb={<AdBreadcrumbs breadcrumbs={breadcrumbs} referer={referer} />}
                    header={
                        <>
                            <ProjectMediaGallery
                                advert={ad}
                                openGalleryRef={openGalleryRef}
                                isAdvancedGallery={isAdvancedGallery}
                                shouldDisplayPredefinedContactMessages={shouldDisplayPredefinedContactMessages}
                            />
                            <ProjectAdHeader ad={ad} />
                            <ProjectTopInformationBoxes ad={ad} />
                        </>
                    }
                    sidebar={
                        <>
                            {isMobile ? null : (
                                <>
                                    <ContactDetails
                                        beforeContactForm={
                                            <LazyContactPersonBanner
                                                contactFormVariant="aside"
                                                {...contactPersonBannerAdvertProps}
                                                onContactButtonClick={handleContactButtonClick}
                                                onPhoneNumberClick={handlePhoneNumberClick}
                                            />
                                        }
                                        contactFormVariant="aside"
                                        shouldDisplayPredefinedContactMessages={shouldDisplayPredefinedContactMessages}
                                        {...contactFormAdvertProps}
                                    />
                                    <SubscribeAdSection
                                        adId={ad.id}
                                        handleSubscribeButtonClick={handleSubscribeButtonClick}
                                    />
                                </>
                            )}
                            <AdReportAd ad={ad} />
                            {ad.agency ? (
                                <AdAgencyBanner
                                    adId={ad.id}
                                    agencyId={ad.agency.id}
                                    adCategory={ad.adCategory}
                                    sellerBadges={adOwner.sellerBadges}
                                    {...ad.agency}
                                />
                            ) : null}
                        </>
                    }
                    content={
                        <>
                            {shouldDisplayUnits ? (
                                <AdInvestmentUnits ad={ad} isNewProjectPage ref={adsListRef} />
                            ) : null}

                            <AdMap
                                title={t('frontend.projectpage.map.title')}
                                noExactLocationLabel={t('frontend.projectpage.map.no-exact-location')}
                                location={ad.location}
                            />

                            <ProjectAdditionalInformation ad={ad} />

                            <ProjectPageDescription content={ad.description} />

                            {ad.agency?.leaderYear ? <AdLeaderBanner rewardYear={ad.agency.leaderYear} /> : null}

                            {videoLink ? (
                                <>
                                    <AdSectionHeading isBorderless>
                                        {t('frontend.ad.video-section.title')}
                                    </AdSectionHeading>
                                    <VideoPlayer videoLink={videoLink} thumbnailUrl={ad.videoThumbnailUrl} />
                                </>
                            ) : null}

                            {shouldDisplayUnits ? <ScrollToAdsListButton adsListRef={adsListRef} /> : null}

                            <AdSectionSecondaryHeading>
                                {t('frontend.ad.contact-person-banner.title')}
                            </AdSectionSecondaryHeading>

                            <LazyContactPersonBanner
                                {...contactPersonBannerAdvertProps}
                                onContactButtonClick={handleContactButtonClick}
                                onPhoneNumberClick={handlePhoneNumberClick}
                            />

                            <RecommendedAdsAdContentWrapper
                                agency={ad.agency}
                                recommendedAdverts={recommendedAdverts}
                                userAdverts={ad.userAdverts}
                                adCategory={ad.adCategory}
                            />

                            <AdMeta ad={ad} />
                        </>
                    }
                    data-cy="newProjectPage"
                    isNewProjectPage
                />
                {modalVisibility === 'none' ? null : (
                    <LazyContactModal
                        agency={ad.agency}
                        contactDetails={ad.contactDetails}
                        variant={modalVisibility}
                        additionalInformation={ad.additionalInformation}
                        afterContactForm={
                            isMobile ? (
                                <SubscribeAdSection
                                    adId={ad.id}
                                    shouldAddPadding={false}
                                    handleSubscribeButtonClick={handleSubscribeButtonClick}
                                />
                            ) : null
                        }
                        beforeContactForm={
                            <LazyContactPersonBanner
                                {...contactPersonBannerAdvertProps}
                                contactFormVariant="default"
                                onPhoneNumberClick={handlePhoneNumberClick}
                            />
                        }
                        shouldDisplayPredefinedContactMessages={shouldDisplayPredefinedContactMessages}
                        onDimiss={(): void => toggleModalState('none')}
                        onSubmit={(): void => toggleModalState('none')}
                        {...contactFormAdvertProps}
                    />
                )}
                {isRedirectToLoginModalOpen ? (
                    <ObserveAdModal
                        onClose={(): void => setIsRedirectToLoginModalOpen(false)}
                        extraTrackingData={{
                            touch_point_page: 'ad_page',
                            touch_point_button: 'favourite_ad_click',
                        }}
                        onSuccessfulLogin={(): Promise<boolean | undefined> => toggleSubscription({ id: ad.id })}
                        id={ad.id}
                    />
                ) : null}

                {isMobile ? (
                    <LazyMobileStickyFooter
                        adId={ad.id}
                        phone={ad.contactDetails?.phones[0]}
                        onMessageClick={handleMobileStickyFooterClick}
                    />
                ) : null}
            </MainLayout>
        </>
    );
};

import { RangeField } from '@domains/shared/components/RangeField/RangeField';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { JSX } from 'react';
import { useCallback, useContext } from 'react';

import { AreaLabel } from './AreaField.theme';

interface Props {
    placeholderMin?: string;
    placeholderMax?: string;
    isRenderedInAdPageInvestment?: boolean;
}

export const AreaField = ({
    placeholderMin = 'frontend.search.area-field.min-field-placeholder',
    placeholderMax = 'frontend.search.area-field.max-field-placeholder',
    isRenderedInAdPageInvestment = false,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    const { trackEvent } = useTracking();
    const trackOnAreaRangeBlur = useCallback(
        (minValue: string, maxValue: string): void => {
            trackEvent('surface_selected', {
                from_surface: minValue,
                to_surface: maxValue,
                touch_point_page: isRenderedInAdPageInvestment ? 'project_page' : 'listing',
            });
        },
        [isRenderedInAdPageInvestment, trackEvent],
    );

    return (
        <>
            {isRenderedInAdPageInvestment || !isDesktop ? (
                <AreaLabel htmlFor={SEARCH_FORM_UNIVERSAL_FIELD.areaMin}>
                    {t('frontend.search.area-field.mobile-label')}
                </AreaLabel>
            ) : null}
            <RangeField
                minInput={{
                    dataCy: 'frontend.search.area-field.min-field-label',
                    name: SEARCH_FORM_UNIVERSAL_FIELD.areaMin,
                    id: SEARCH_FORM_UNIVERSAL_FIELD.areaMin,
                    label: t('frontend.search.form.area-unit'),
                    ariaLabel: t('frontend.search.area-field.min-field-label'),
                    placeholder: t(placeholderMin),
                }}
                maxInput={{
                    dataCy: 'frontend.search.area-field.max-field-label',
                    name: SEARCH_FORM_UNIVERSAL_FIELD.areaMax,
                    id: SEARCH_FORM_UNIVERSAL_FIELD.areaMax,
                    label: t('frontend.search.form.area-unit'),
                    ariaLabel: t('frontend.search.area-field.max-field-label'),
                    placeholder: t(placeholderMax),
                }}
                onBlur={trackOnAreaRangeBlur}
            />
        </>
    );
};

import { getImageUrl } from '@domains/ad/helpers/getImageUrl';
import type { Ad as LegacyAdvert } from '@domains/ad/types/Ad';
import { repeatItem } from '@domains/shared/helpers/repeatItem';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import type { JSX } from 'react';
import { useCallback, useState } from 'react';

import type { OpenGalleryRef } from './openGalleryRef';
import { MainPicture, PlaceholderContainer, ThumbnailsContainer, ThumbnailSkeleton } from './ProjectMediaGallery.theme';

const LazyImageGallery = dynamic(
    () => import('@domains/ad/components/ProjectPage/components/ProjectMediaGallery/ImageGallery'),
    { ssr: false },
);

const THUMBNAILS: JSX.Element[] = repeatItem(3, (id) => <ThumbnailSkeleton key={id} />);

interface Props {
    advert: LegacyAdvert;
    openGalleryRef: OpenGalleryRef;
    shouldDisplayPredefinedContactMessages?: boolean;
    isAdvancedGallery?: boolean;
}

export const ProjectMediaGallery = ({
    advert,
    openGalleryRef,
    shouldDisplayPredefinedContactMessages,
    isAdvancedGallery,
}: Props): JSX.Element | null => {
    const [shouldShowPlaceholder, setShowPlaceholder] = useState(true);

    const onImageGalleryLoad = useCallback(() => {
        setShowPlaceholder(false);
    }, []);

    const [mainImage] = advert.images;

    if (!mainImage) {
        return null;
    }

    const mediumImageUrl = getImageUrl(mainImage.medium);
    const largeImageUrl = getImageUrl(mainImage.large);

    return (
        <>
            <Head>
                <link rel="preload" href={mediumImageUrl} as="image" media={`(max-width: ${BREAKPOINT.sm})`} />
                <link rel="preload" href={largeImageUrl} as="image" media={`(min-width: ${BREAKPOINT.sm})`} />
            </Head>

            {shouldShowPlaceholder ? (
                <PlaceholderContainer>
                    <MainPicture>
                        <source media={`(max-width: ${BREAKPOINT.sm})`} srcSet={mediumImageUrl} />
                        <source media={`(min-width: ${BREAKPOINT.sm})`} srcSet={largeImageUrl} />
                        <img src={largeImageUrl} alt={advert.title} />
                    </MainPicture>
                    <ThumbnailsContainer>{THUMBNAILS}</ThumbnailsContainer>
                </PlaceholderContainer>
            ) : null}
            <LazyImageGallery
                advert={advert}
                onLoad={onImageGalleryLoad}
                openGalleryRef={openGalleryRef}
                startTransparent
                shouldDisplayPredefinedContactMessages={shouldDisplayPredefinedContactMessages}
                isAdvancedGallery={isAdvancedGallery}
            />
        </>
    );
};

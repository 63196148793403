import { TooltipDescription } from '@domains/shared/components/TooltipIcon/Tooltip.theme';
import { TooltipIcon } from '@domains/shared/components/TooltipIcon/TooltipIcon';
import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Container = styled.div`
    display: flex;
    grid-area: price;
    flex-wrap: wrap;
    align-items: center;
    align-self: start;
`;

export const Price = styled.b`
    margin-right: 16px;
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.ad.projectAdHeader.price.color};
    font-size: ${SIZE.h5};
    font-weight: ${WEIGHT.semibold};
    text-align: left;

    @media (min-width: ${BREAKPOINT.xs}) {
        font-size: ${SIZE.h4};
    }
`;

export const PriceBeforeDiscount = styled.span`
    margin-right: 16px;
    color: ${({ theme }): CSSProperties['color'] =>
        theme.deprecated.domains.ad.projectAdHeader.priceBeforeDiscount.price.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.regular};
    text-align: left;
    text-decoration: line-through;

    @media (min-width: ${BREAKPOINT.md}) {
        margin-right: 0;
        font-size: ${SIZE.p1};
    }
`;

export const StyledTooltipIcon = styled(TooltipIcon)`
    margin-left: 8px;
    color: ${({ theme }): CSSProperties['color'] =>
        theme.deprecated.domains.ad.projectAdHeader.priceBeforeDiscount.tooltip.color};
    font-size: ${SIZE.p3};

    ${TooltipDescription} {
        text-align: left;
    }
`;

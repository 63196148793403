import { AreaField } from '@domains/shared/components/AreaField/AreaField';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { RangeField } from '@domains/shared/components/RangeField/RangeField';
import { RoomsField } from '@domains/shared/components/RoomsField/RoomsField';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { JSX } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

import {
    AreaContainer,
    ClearCriteriaButton,
    Form,
    InputLabel,
    PriceContainer,
    RoomsContainer,
    SubmitButton,
} from './AdInvestmentSearch.theme';

type FormValue = string | string[] | null | undefined;
interface Props {
    clearSearchCriteria: () => void;
    formMethods: UseFormReturn<{
        areaMax: FormValue;
        areaMin: FormValue;
        priceMin: FormValue;
        priceMax: FormValue;
        roomsNumber: string | string[] | null;
    }>;
    onFormSubmit: () => void;
    isNewProjectPage?: boolean;
}

export const AdInvestmentSearch = ({
    clearSearchCriteria,
    formMethods,
    onFormSubmit,
    isNewProjectPage,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const priceMinConfig = useMemo(
        () => ({
            dataCy: 'frontend.search.form.field.priceMin.placeholder',
            name: SEARCH_FORM_UNIVERSAL_FIELD.priceMin,
            id: SEARCH_FORM_UNIVERSAL_FIELD.priceMin,
            label: t('frontend.ad.investment.search.currency'),
            placeholder: t('frontend.ad.investment.search.priceMin.placeholder'),
            ariaLabel: t('frontend.ad.investment.search.priceMin.placeholder'),
        }),
        [t],
    );

    const priceMaxConfig = useMemo(
        () => ({
            dataCy: 'frontend.search.form.field.priceMax.placeholder',
            name: SEARCH_FORM_UNIVERSAL_FIELD.priceMax,
            id: SEARCH_FORM_UNIVERSAL_FIELD.priceMax,
            label: t('frontend.ad.investment.search.currency'),
            placeholder: t('frontend.ad.investment.search.priceMax.placeholder'),
            ariaLabel: t('frontend.ad.investment.search.priceMax.placeholder'),
        }),
        [t],
    );

    const trackOnPriceRangeBlur = useCallback(
        (minValue: string, maxValue: string): void => {
            trackEvent('price_selected', {
                from_price: minValue,
                to_price: maxValue,
                touch_point_page: 'project_page',
            });
        },
        [trackEvent],
    );

    const { handleSubmit } = formMethods;

    return (
        <FormProvider {...formMethods}>
            <Form
                onSubmit={handleSubmit(onFormSubmit)}
                aria-label={t('frontend.search.form.title')}
                noValidate
                isNewProjectPage={isNewProjectPage}
            >
                <PriceContainer shouldAddSeparator={true} isNewProjectPage={isNewProjectPage}>
                    <InputLabel>{t('frontend.ad.investment.search.price-label')}</InputLabel>
                    <RangeField minInput={priceMinConfig} maxInput={priceMaxConfig} onBlur={trackOnPriceRangeBlur} />
                </PriceContainer>
                <AreaContainer shouldAddSeparator={true} isNewProjectPage={isNewProjectPage}>
                    <AreaField
                        placeholderMin="frontend.ad.investment.search.areaMin.placeholder"
                        placeholderMax="frontend.ad.investment.search.areaMax.placeholder"
                        isRenderedInAdPageInvestment={true}
                    />
                </AreaContainer>
                <RoomsContainer isNewProjectPage={isNewProjectPage}>
                    <RoomsField
                        placeholder="frontend.ad.investment.search.number-of-rooms-placeholder"
                        isRenderedInAdPageInvestment={true}
                    />
                </RoomsContainer>
                <SubmitButton
                    variant={ButtonVariant.Ghost}
                    type="submit"
                    data-cy="ad-search-investment-submit-button"
                    isNewProjectPage={isNewProjectPage}
                >
                    {t('frontend.ad.investment.search.submit-placeholder')}
                </SubmitButton>
                <ClearCriteriaButton
                    type="reset"
                    variant="secondary"
                    onClick={clearSearchCriteria}
                    data-cy="ad-search-investment-clear-criteria"
                    isNewProjectPage={isNewProjectPage}
                >
                    {t('frontend.ad.investment.search.clear-criteria')}
                </ClearCriteriaButton>
            </Form>
        </FormProvider>
    );
};

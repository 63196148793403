import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 476px;
    margin-bottom: 40px;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
    }
`;

export const PlayButton = styled(Icon)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.ad.videoPlayer.playButton.color};
    cursor: pointer;
    filter: ${({ theme }): CSSProperties['filter'] =>
        `drop-shadow(${theme.deprecated.domains.ad.videoPlayer.playButton.dropShadow})`};

    a {
        margin-top: 9px;
    }
`;

export const Player = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
        theme.deprecated.domains.ad.videoPlayer.player.backgroundColor};
`;

import AdPageTopBar from '@domains/ad/nexusComponents/AdPageTopBar/AdPageTopBar';
import { AgencyBranding } from '@domains/ad/nexusComponents/AgencyBranding/AgencyBranding';
import {
    AsideContent,
    Container,
    FullWidthMainContentSection,
    MainContent,
} from '@domains/ad/nexusComponents/PageContent/PageContent.theme';
import type { Ad } from '@domains/ad/types/Ad';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import type { JSX } from 'react';

interface Props {
    ad: Ad;
    referer: string | null;
}

export const NexusInvestmentPageContent = ({ ad, referer }: Props): JSX.Element => {
    const shouldShowAgencyBranding = !!ad.agency?.brandingVisible;

    return (
        <>
            {shouldShowAgencyBranding ? (
                <>
                    <FullWidthMainContentSection>
                        <AgencyBranding agency={ad.agency} adCategory={ad.adCategory} />
                    </FullWidthMainContentSection>
                    <Divider variant="secondary-light" />
                </>
            ) : null}

            <Container data-testid="nexus-investment-page">
                <FullWidthMainContentSection>
                    <AdPageTopBar referer={referer} adTitle={ad.title} adUrl={ad.url} adId={ad.id} />
                </FullWidthMainContentSection>

                <MainContent></MainContent>
                <AsideContent></AsideContent>
            </Container>
            <FullWidthMainContentSection addTopSpacing></FullWidthMainContentSection>
        </>
    );
};

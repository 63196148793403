import { SpecialOfferBadge } from '@domains/ad/components/SpecialOfferBadge/SpecialOfferBadge';
import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import { getAdPrice } from '@domains/ad/helpers/getAdPrice';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import type { SpecialOffer } from '@type/ad/specialOffer';
import { SPECIAL_OFFER_VARIANT } from '@type/ad/specialOffer';
import type { PriceType } from '@type/pricing/advertPrice';
import type { JSX } from 'react';

import { Container, Price, PriceBeforeDiscount, StyledTooltipIcon } from './ProjectPrice.theme';

interface Props {
    specialOffer: SpecialOffer | null;
    characteristics: AdvertCharacteristic[];
    isDesktop: boolean | null;
    priceType?: PriceType;
}

export const ProjectPrice = ({ characteristics, specialOffer, isDesktop, priceType }: Props): JSX.Element => {
    const [t] = useTranslations();

    const getData = getAdCharacteristic(characteristics);
    const price = getData('price_per_m_from');

    if (!price?.localizedValue || priceType === 'ON_DEMAND') {
        return (
            <Container>
                <Price aria-label={t('frontend.ad.ad-header.price')} data-cy="adPageHeaderPrice">
                    {t('frontend.ad.ad-header.ask-for-price')}
                </Price>
                {isDesktop && specialOffer ? <SpecialOfferBadge specialOffer={specialOffer} /> : null}
            </Container>
        );
    }

    const isDiscountOffer = specialOffer?.__typename === SPECIAL_OFFER_VARIANT.priceDiscount;

    const { discountedPrice, priceBeforeDiscount } = getAdPrice({
        price: price,
        specialOffer,
        isInvestment: true,
    });

    const oldPrice = specialOffer ? (
        <div>
            {isDiscountOffer ? (
                <PriceBeforeDiscount>
                    {priceBeforeDiscount}
                    <StyledTooltipIcon description={t('frontend.global.price.lowest-within-last-30-days')} />
                </PriceBeforeDiscount>
            ) : null}
            {isDiscountOffer || isDesktop ? <SpecialOfferBadge specialOffer={specialOffer} /> : null}
        </div>
    ) : null;

    return (
        <Container>
            <Price aria-label={t('frontend.ad.ad-header.price')} data-cy="adPageHeaderPrice">
                {`${t('frontend.ad.project-ad-header.price-from')} ${discountedPrice ?? price.localizedValue}`}
            </Price>
            {oldPrice}
        </Container>
    );
};

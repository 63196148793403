import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import {
    Placeholder,
    RemoveSelectedOptionButton,
    ValueChip,
    ValueString,
} from '@domains/shared/components/DropdownWithCheckboxes/DropdownWithCheckboxes.theme';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import type { FilterOption as Option } from '@type/search/filters/option';
import type { JSX, MouseEvent } from 'react';

interface Props {
    pickedOptions: Option<string>[] | string | null;
    withChips: boolean;
    onChipsDelete: (event: MouseEvent, value: string) => void;
    placeholder?: string;
    title?: string;
}

export const DropdownValue = ({ pickedOptions, placeholder, withChips, onChipsDelete, title }: Props): JSX.Element => {
    if (!pickedOptions) {
        return <Placeholder>{placeholder}</Placeholder>;
    }

    if (withChips && Array.isArray(pickedOptions)) {
        return (
            <>
                {pickedOptions.map((option) => (
                    <ValueChip key={option.value}>
                        {option.label}
                        <RemoveSelectedOptionButton
                            onClick={(event: MouseEvent): void => onChipsDelete(event, option.value)}
                        >
                            <Icon icon={faTimes} size="sm" />
                        </RemoveSelectedOptionButton>
                    </ValueChip>
                ))}
            </>
        );
    }

    return (
        <ValueString>
            {title ? `${title}: ` : null}
            {String(pickedOptions)}
        </ValueString>
    );
};

import { Button } from '@domains/shared/components/Button/Button';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';

export const StyledButton = styled(Button)`
    width: 100%;
`;

export const SubscribeButtonIcon = styled(Icon)`
    margin-right: 8px;
`;

import { checkIsDesktop } from '@domains/shared/helpers/checkIsDesktop';
import { useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

/**
 * @deprecated Please use `RWDContext` instead.
 */
export const useCheckIsDesktop = (): boolean => {
    const [isDesktop, setIsDesktop] = useState(false);
    const [resizeTimeoutHandler, setResizeTimeoutHandler] = useState<ReturnType<typeof setTimeout>>();

    const updateIsDesktop = (): void => {
        resizeTimeoutHandler && clearTimeout(resizeTimeoutHandler);

        const timeout = setTimeout(() => {
            setIsDesktop(checkIsDesktop());
        }, 100);

        setResizeTimeoutHandler(timeout);
    };

    useIsomorphicLayoutEffect(() => {
        setIsDesktop(checkIsDesktop());

        window.addEventListener('resize', updateIsDesktop, false);

        return (): void => {
            resizeTimeoutHandler && clearTimeout(resizeTimeoutHandler);

            window.removeEventListener('resize', updateIsDesktop);
        };
    }, [resizeTimeoutHandler, setResizeTimeoutHandler]);

    return isDesktop;
};

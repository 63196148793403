import type { AdInformationValue } from '@type/ad/adInformationValue';

/* eslint-disable no-restricted-syntax -- deserves its own MR */
export const AD_INFORMATION_VALUE_LABEL: Record<AdInformationValue, string> = {
    '0': '',
    '1': '',
    '2': '',
    '3': '',
    '4': '',
    '5': '',
    '6': '',
    '7': '',
    ['A']: '',
    ['B']: '',
    ['C']: '',
    ['D']: '',
    ['E']: '',
    ['F']: '',
    '::n': 'frontend.ad.table-information-value--n',
    '::y': 'frontend.ad.table-information-value--y',
    'access_types::asphalt': 'frontend.ad.table-information-value-access_types-asphalt',
    'access_types::dirt': 'frontend.ad.table-information-value-access_types-dirt',
    'access_types::hard_surfaced': 'frontend.ad.table-information-value-access_types-hard_surfaced',
    'access_types::soft_surfaced': 'frontend.ad.table-information-value-access_types-soft_surfaced',
    'advertiser_type::agency': 'frontend.ad.table-information-value-advertiser_type-agency',
    'advertiser_type::developer': 'frontend.ad.table-information-value-advertiser_type-developer',
    'advertiser_type::private': 'frontend.ad.table-information-value-advertiser_type-private',
    'apartment_room_count::10-or-more': 'frontend.ad.table-information-value-apartment_room_count-10-or-more',
    balcony: 'frontend.ad.table-information-value-balcony',
    'boiler::room': 'frontend.ad.table-information-value-boiler-room',
    'building_material::117-brick': 'frontend.ad.table-information-value-building_material-117-brick',
    'building_material::117-wood': 'frontend.ad.table-information-value-building_material-117-wood',
    'building_material::69-brick': 'frontend.ad.table-information-value-building_material-69-brick',
    'building_material::69-wood': 'frontend.ad.table-information-value-building_material-69-wood',
    'building_material::breezeblock': 'frontend.ad.table-information-value-building_material-breezeblock',
    'building_material::brick': 'frontend.ad.table-information-value-building_material-brick',
    'building_material::cellular_concrete': 'frontend.ad.table-information-value-building_material-cellular_concrete',
    'building_material::concrete': 'frontend.ad.table-information-value-building_material-concrete',
    'building_material::concrete_plate': 'frontend.ad.table-information-value-building_material-concrete_plate',
    'building_material::hydroton': 'frontend.ad.table-information-value-building_material-hydroton',
    'building_material::other': 'frontend.ad.table-information-value-building_material-other',
    'building_material::reinforced_concrete':
        'frontend.ad.table-information-value-building_material-reinforced_concrete',
    'building_material::silikat': 'frontend.ad.table-information-value-building_material-silikat',
    'building_material::wood': 'frontend.ad.table-information-value-building_material-wood',
    'building_ownership::co_operative_ownership':
        'frontend.ad.table-information-value-building_ownership-co_operative_ownership',
    'building_ownership::co_operative_ownership_with_a_land_and_mortgage_registe':
        'frontend.ad.table-information-value-building_ownership-co_operative_ownership_with_a_land_and_mortgage_registe',
    'building_ownership::full_ownership': 'frontend.ad.table-information-value-building_ownership-full_ownership',
    'building_ownership::share': 'frontend.ad.table-information-value-building_ownership-share',
    'building_type::145-block': 'frontend.ad.table-information-value-building_type-145-block',
    'building_type::15-block': 'frontend.ad.table-information-value-building_type-15-block',
    'building_type::155-block': 'frontend.ad.table-information-value-building_type-155-block',
    'building_type::apartment': 'frontend.ad.table-information-value-building_type-apartment',
    'building_type::detached': 'frontend.ad.table-information-value-building_type-detached',
    'building_type::farm': 'frontend.ad.table-information-value-building_type-farm',
    'building_type::historical_building': 'frontend.ad.table-information-value-building_type-historical_building',
    'building_type::house': 'frontend.ad.table-information-value-building_type-house',
    'building_type::infill': 'frontend.ad.table-information-value-building_type-infill',
    'building_type::loft': 'frontend.ad.table-information-value-building_type-loft',
    'building_type::office_building': 'frontend.ad.table-information-value-building_type-office_building',
    'building_type::private_house': 'frontend.ad.table-information-value-building_type-private_house',
    'building_type::residence': 'frontend.ad.table-information-value-building_type-residence',
    'building_type::ribbon': 'frontend.ad.table-information-value-building_type-ribbon',
    'building_type::semi_detached': 'frontend.ad.table-information-value-building_type-semi_detached',
    'building_type::separate': 'frontend.ad.table-information-value-building_type-separate',
    'building_type::shopping_centre': 'frontend.ad.table-information-value-building_type-shopping_centre',
    'building_type::tenement': 'frontend.ad.table-information-value-building_type-tenement',
    'building_type::tenement_house': 'frontend.ad.table-information-value-building_type-tenement_house',
    'co::operative-ownership': 'frontend.ad.table-information-value-co-operative-ownership',
    'co::operative-ownership-with-a-land-and-mortgage-register':
        'frontend.ad.table-information-value-co-operative-ownership-with-a-land-and-mortgage-register',
    'condition::for-renewal': 'frontend.ad.table-information-value-condition-for-renewal',
    'condition::new': 'frontend.ad.table-information-value-condition-new',
    'construction_status::107-ready_to_use': 'frontend.ad.table-information-value-construction_status-107-ready_to_use',
    'construction_status::113-ready_to_use': 'frontend.ad.table-information-value-construction_status-113-ready_to_use',
    'construction_status::121-ready_to_use': 'frontend.ad.table-information-value-construction_status-121-ready_to_use',
    'construction_status::141-ready_to_use': 'frontend.ad.table-information-value-construction_status-141-ready_to_use',
    'construction_status::67-ready_to_use': 'frontend.ad.table-information-value-construction_status-67-ready_to_use',
    'construction_status::to_completion': 'frontend.ad.table-information-value-construction_status-to_completion',
    'construction_status::to_renovation': 'frontend.ad.table-information-value-construction_status-to_renovation',
    'construction_status::unfinished_close': 'frontend.ad.table-information-value-construction_status-unfinished_close',
    'construction_status::unfinished_open': 'frontend.ad.table-information-value-construction_status-unfinished_open',
    'detached::garage': 'frontend.ad.table-information-value-detached-garage',
    'e::signature': 'frontend.ad.table-information-value-e-signature',
    electrical: 'frontend.ad.table-information-value-electrical',
    'enclosed::area-private-parking': 'frontend.ad.table-information-value-enclosed-area-private-parking',
    'equipment_types::dishwasher': 'frontend.ad.table-information-value-equipment_types-dishwasher',
    'equipment_types::fridge': 'frontend.ad.table-information-value-equipment_types-fridge',
    'equipment_types::furniture': 'frontend.ad.table-information-value-equipment_types-furniture',
    'equipment_types::oven': 'frontend.ad.table-information-value-equipment_types-oven',
    'equipment_types::stove': 'frontend.ad.table-information-value-equipment_types-stove',
    'equipment_types::tv': 'frontend.ad.table-information-value-equipment_types-tv',
    'equipment_types::washing_machine': 'frontend.ad.table-information-value-equipment_types-washing_machine',
    'extras_types::179-garage': 'frontend.ad.table-information-value-extras_types-179-garage',
    'extras_types::181-garage': 'frontend.ad.table-information-value-extras_types-181-garage',
    'extras_types::219-garage': 'frontend.ad.table-information-value-extras_types-219-garage',
    'extras_types::85-garage': 'frontend.ad.table-information-value-extras_types-85-garage',
    'extras_types::87-garage': 'frontend.ad.table-information-value-extras_types-87-garage',
    'extras_types::air_conditioning': 'frontend.ad.table-information-value-extras_types-air_conditioning',
    'extras_types::asphalt_access': 'frontend.ad.table-information-value-extras_types-asphalt_access',
    'extras_types::attic': 'frontend.ad.table-information-value-extras_types-attic',
    'extras_types::balcony': 'frontend.ad.table-information-value-extras_types-balcony',
    'extras_types::basement': 'frontend.ad.table-information-value-extras_types-basement',
    'extras_types::cellar': 'frontend.ad.table-information-value-extras_types-cellar',
    'extras_types::closed_area': 'frontend.ad.table-information-value-extras_types-closed_area',
    'extras_types::elevator': 'frontend.ad.table-information-value-extras_types-elevator',
    'extras_types::furniture': 'frontend.ad.table-information-value-extras_types-furniture',
    'extras_types::garage': 'frontend.ad.table-information-value-extras_types-garage',
    'extras_types::garden': 'frontend.ad.table-information-value-extras_types-garden',
    'extras_types::heating': 'frontend.ad.table-information-value-extras_types-heating',
    'extras_types::lift': 'frontend.ad.table-information-value-extras_types-lift',
    'extras_types::non_smokers_only': 'frontend.ad.table-information-value-extras_types-non_smokers_only',
    'extras_types::parking': 'frontend.ad.table-information-value-extras_types-parking',
    'extras_types::pool': 'frontend.ad.table-information-value-extras_types-pool',
    'extras_types::security': 'frontend.ad.table-information-value-extras_types-security',
    'extras_types::separate_kitchen': 'frontend.ad.table-information-value-extras_types-separate_kitchen',
    'extras_types::shop_window': 'frontend.ad.table-information-value-extras_types-shop_window',
    'extras_types::terrace': 'frontend.ad.table-information-value-extras_types-terrace',
    'extras_types::terraces': 'frontend.ad.table-information-value-extras_types-terraces',
    'extras_types::two_storey': 'frontend.ad.table-information-value-extras_types-two_storey',
    'extras_types::usable_room': 'frontend.ad.table-information-value-extras_types-usable_room',
    'extra_spaces::balcony': 'frontend.ad.table-information-value-extra_spaces-balcony',
    'extra_spaces::basement': 'frontend.ad.table-information-value-extra_spaces-basement',
    'extra_spaces::bicycle_room': 'frontend.ad.table-information-value-extra_spaces-bicycle_room',
    'extra_spaces::ground_parking_space': 'frontend.ad.table-information-value-extra_spaces-ground_parking_space',
    'extra_spaces::storage_room': 'frontend.ad.table-information-value-extra_spaces-storage_room',
    'extra_spaces::terrace': 'frontend.ad.table-information-value-extra_spaces-terrace',
    'extra_spaces::underground_parking_space':
        'frontend.ad.table-information-value-extra_spaces-underground_parking_space',
    'fence::n': 'frontend.ad.table-information-value-fence-n',
    'fence::y': 'frontend.ad.table-information-value-fence-y',
    'fence_types::177-brick': 'frontend.ad.table-information-value-fence_types-177-brick',
    'fence_types::brick': 'frontend.ad.table-information-value-fence_types-brick',
    'fence_types::concrete': 'frontend.ad.table-information-value-fence_types-concrete',
    'fence_types::hedge': 'frontend.ad.table-information-value-fence_types-hedge',
    'fence_types::metal': 'frontend.ad.table-information-value-fence_types-metal',
    'fence_types::other': 'frontend.ad.table-information-value-fence_types-other',
    'fence_types::wire': 'frontend.ad.table-information-value-fence_types-wire',
    'fence_types::wooden': 'frontend.ad.table-information-value-fence_types-wooden',
    'flooring::none': 'frontend.ad.table-information-value-flooring-none',
    'flooring::pollen': 'frontend.ad.table-information-value-flooring-pollen',
    'flooring::unpollen': 'frontend.ad.table-information-value-flooring-unpollen',
    'floors_num::ground_floor': 'frontend.ad.table-information-value-floors_num-ground_floor',
    'floors_num::more': 'frontend.ad.table-information-value-floors_num-more',
    'floors_num::one_floor': 'frontend.ad.table-information-value-floors_num-one_floor',
    'floors_num::two_floors': 'frontend.ad.table-information-value-floors_num-two_floors',
    'floor_no::cellar': 'frontend.ad.table-information-value-floor_no-cellar',
    'floor_no::floor_1': 'frontend.ad.table-information-value-floor_no-floor_1',
    'floor_no::floor_10': 'frontend.ad.table-information-value-floor_no-floor_10',
    'floor_no::floor_2': 'frontend.ad.table-information-value-floor_no-floor_2',
    'floor_no::floor_3': 'frontend.ad.table-information-value-floor_no-floor_3',
    'floor_no::floor_4': 'frontend.ad.table-information-value-floor_no-floor_4',
    'floor_no::floor_5': 'frontend.ad.table-information-value-floor_no-floor_5',
    'floor_no::floor_6': 'frontend.ad.table-information-value-floor_no-floor_6',
    'floor_no::floor_7': 'frontend.ad.table-information-value-floor_no-floor_7',
    'floor_no::floor_8': 'frontend.ad.table-information-value-floor_no-floor_8',
    'floor_no::floor_9': 'frontend.ad.table-information-value-floor_no-floor_9',
    'floor_no::floor_higher_10': 'frontend.ad.table-information-value-floor_no-floor_higher_10',
    'floor_no::garret': 'frontend.ad.table-information-value-floor_no-garret',
    'floor_no::ground_floor': 'frontend.ad.table-information-value-floor_no-ground_floor',
    'for::renewal': 'frontend.ad.table-information-value-for-renewal',
    'full::ownership': 'frontend.ad.table-information-value-full-ownership',
    garage: 'frontend.ad.table-information-value-garage',
    'garage::in-the-building': 'frontend.ad.table-information-value-garage-in-the-building',
    garden: 'frontend.ad.table-information-value-garden',
    'garret_type::notany': 'frontend.ad.table-information-value-garret_type-notany',
    'garret_type::unusable': 'frontend.ad.table-information-value-garret_type-unusable',
    'garret_type::usable': 'frontend.ad.table-information-value-garret_type-usable',
    gas: 'frontend.ad.table-information-value-gas',
    'heating::boiler_room': 'frontend.ad.table-information-value-heating-boiler_room',
    'heating::electrical': 'frontend.ad.table-information-value-heating-electrical',
    'heating::gas': 'frontend.ad.table-information-value-heating-gas',
    'heating::n': 'frontend.ad.table-information-value-heating-n',
    'heating::other': 'frontend.ad.table-information-value-heating-other',
    'heating::tiled_stove': 'frontend.ad.table-information-value-heating-tiled_stove',
    'heating::urban': 'frontend.ad.table-information-value-heating-urban',
    'heating::y': 'frontend.ad.table-information-value-heating-y',
    'heating_types::biomass': 'frontend.ad.table-information-value-heating_types-biomass',
    'heating_types::coal': 'frontend.ad.table-information-value-heating_types-coal',
    'heating_types::electric': 'frontend.ad.table-information-value-heating_types-electric',
    'heating_types::fireplace': 'frontend.ad.table-information-value-heating_types-fireplace',
    'heating_types::gas': 'frontend.ad.table-information-value-heating_types-gas',
    'heating_types::geothermal': 'frontend.ad.table-information-value-heating_types-geothermal',
    'heating_types::heat_pump': 'frontend.ad.table-information-value-heating_types-heat_pump',
    'heating_types::oil': 'frontend.ad.table-information-value-heating_types-oil',
    'heating_types::solar_collector': 'frontend.ad.table-information-value-heating_types-solar_collector',
    'heating_types::stove': 'frontend.ad.table-information-value-heating_types-stove',
    'heating_types::urban': 'frontend.ad.table-information-value-heating_types-urban',
    'hide_price::0': 'frontend.ad.table-information-value-hide_price-0',
    'hide_price::1': 'frontend.ad.table-information-value-hide_price-1',
    'is_bungalow::0': 'frontend.ad.table-information-value-is_bungalow-0',
    'is_bungalow::1': 'frontend.ad.table-information-value-is_bungalow-1',
    'lighting::n': 'frontend.ad.table-information-value-lighting-n',
    'lighting::y': 'frontend.ad.table-information-value-lighting-y',
    'localization::by_the_house': 'frontend.ad.table-information-value-localization-by_the_house',
    'localization::in_building': 'frontend.ad.table-information-value-localization-in_building',
    'localization::separate': 'frontend.ad.table-information-value-localization-separate',
    'location::city': 'frontend.ad.table-information-value-location-city',
    'location::country': 'frontend.ad.table-information-value-location-country',
    'location::suburban': 'frontend.ad.table-information-value-location-suburban',
    'market::primary': 'frontend.ad.table-information-value-market-primary',
    'market::secondary': 'frontend.ad.table-information-value-market-secondary',
    'media_types::cable-television': 'frontend.ad.table-information-value-media_types-cable-television',
    'media_types::cable_television': 'frontend.ad.table-information-value-media_types-cable_television',
    'media_types::cesspool': 'frontend.ad.table-information-value-media_types-cesspool',
    'media_types::electricity': 'frontend.ad.table-information-value-media_types-electricity',
    'media_types::gas': 'frontend.ad.table-information-value-media_types-gas',
    'media_types::internet': 'frontend.ad.table-information-value-media_types-internet',
    'media_types::phone': 'frontend.ad.table-information-value-media_types-phone',
    'media_types::power': 'frontend.ad.table-information-value-media_types-power',
    'media_types::rafinery': 'frontend.ad.table-information-value-media_types-rafinery',
    'media_types::sewage': 'frontend.ad.table-information-value-media_types-sewage',
    'media_types::telephone': 'frontend.ad.table-information-value-media_types-telephone',
    'media_types::water': 'frontend.ad.table-information-value-media_types-water',
    'media_types::water_purification': 'frontend.ad.table-information-value-media_types-water_purification',
    n: 'frontend.ad.table-information-value-n',
    new: 'frontend.ad.table-information-value-new',
    'non_smokers_only::0': 'frontend.ad.table-information-value-non_smokers_only-0',
    'non_smokers_only::1': 'frontend.ad.table-information-value-non_smokers_only-1',
    'offered_estates_type::commercial_properties':
        'frontend.ad.table-information-value-offered_estates_type-commercial_properties',
    'offered_estates_type::flats': 'frontend.ad.table-information-value-offered_estates_type-flats',
    'offered_estates_type::houses': 'frontend.ad.table-information-value-offered_estates_type-houses',
    'offered_estates_types_project::commercial_properties':
        'frontend.ad.table-information-value-offered_estates_types_project-commercial_properties',
    'offered_estates_types_project::flats': 'frontend.ad.table-information-value-offered_estates_types_project-flats',
    'offered_estates_types_project::houses': 'frontend.ad.table-information-value-offered_estates_types_project-houses',
    'offered_estates_type_project::flats': 'frontend.ad.table-information-value-offered_estates_type_project-flats',
    'office_space::0': 'frontend.ad.table-information-value-office_space-0',
    'office_space::1': 'frontend.ad.table-information-value-office_space-1',
    'outdoor::balcony': 'frontend.ad.table-information-value-outdoor-balcony',
    'outdoor::garden': 'frontend.ad.table-information-value-outdoor-garden',
    'outdoor::terrace': 'frontend.ad.table-information-value-outdoor-terrace',
    parking: 'frontend.ad.table-information-value-parking',
    'parking::asphalt': 'frontend.ad.table-information-value-parking-asphalt',
    'parking::cobblestone': 'frontend.ad.table-information-value-parking-cobblestone',
    'parking::concrete': 'frontend.ad.table-information-value-parking-concrete',
    'parking::hard_surfaced': 'frontend.ad.table-information-value-parking-hard_surfaced',
    'parking::none': 'frontend.ad.table-information-value-parking-none',
    'parking::soft_surfaced': 'frontend.ad.table-information-value-parking-soft_surfaced',
    'preferred_profession::employee': 'frontend.ad.table-information-value-preferred_profession-employee',
    'preferred_profession::none': 'frontend.ad.table-information-value-preferred_profession-none',
    'preferred_profession::student': 'frontend.ad.table-information-value-preferred_profession-student',
    'preferred_sex::man': 'frontend.ad.table-information-value-preferred_sex-man',
    'preferred_sex::pair': 'frontend.ad.table-information-value-preferred_sex-pair',
    'preferred_sex::woman': 'frontend.ad.table-information-value-preferred_sex-woman',
    'price::ondemand': 'frontend.ad.table-information-value-price-ondemand',
    private: 'frontend.ad.table-information-value-private',
    'project_amenities::disabled_friendly': 'frontend.ad.table-information-value-project_amenities-disabled_friendly',
    'project_amenities::elevators': 'frontend.ad.table-information-value-project_amenities-elevators',
    'project_amenities::gym': 'frontend.ad.table-information-value-project_amenities-gym',
    'project_amenities::playground': 'frontend.ad.table-information-value-project_amenities-playground',
    'project_amenities::relax_area': 'frontend.ad.table-information-value-project_amenities-relax_area',
    'project_amenities::sport_fields': 'frontend.ad.table-information-value-project_amenities-sport_fields',
    'project_amenities::swimming_pool': 'frontend.ad.table-information-value-project_amenities-swimming_pool',
    'ramp::0': 'frontend.ad.table-information-value-ramp-0',
    'ramp::1': 'frontend.ad.table-information-value-ramp-1',
    'recreational::0': 'frontend.ad.table-information-value-recreational-0',
    'recreational::1': 'frontend.ad.table-information-value-recreational-1',
    'remote_services::0': 'frontend.ad.table-information-value-remote_services-0',
    'remote_services::1': 'frontend.ad.table-information-value-remote_services-1',
    'rent_to_students::0': 'frontend.ad.table-information-value-rent_to_students-0',
    'rent_to_students::1': 'frontend.ad.table-information-value-rent_to_students-1',
    'roofing::asbestic_tile': 'frontend.ad.table-information-value-roofing-asbestic_tile',
    'roofing::chopper': 'frontend.ad.table-information-value-roofing-chopper',
    'roofing::other': 'frontend.ad.table-information-value-roofing-other',
    'roofing::roofing_paper': 'frontend.ad.table-information-value-roofing-roofing_paper',
    'roofing::sheet': 'frontend.ad.table-information-value-roofing-sheet',
    'roofing::shingle': 'frontend.ad.table-information-value-roofing-shingle',
    'roofing::slate': 'frontend.ad.table-information-value-roofing-slate',
    'roofing::tile': 'frontend.ad.table-information-value-roofing-tile',
    'roof_type::diagonal': 'frontend.ad.table-information-value-roof_type-diagonal',
    'roof_type::flat': 'frontend.ad.table-information-value-roof_type-flat',
    'roof_type::notany': 'frontend.ad.table-information-value-roof_type-notany',
    'roomsize::one': 'frontend.ad.table-information-value-roomsize-one',
    'roomsize::three': 'frontend.ad.table-information-value-roomsize-three',
    'roomsize::two': 'frontend.ad.table-information-value-roomsize-two',
    'rooms_num::10-or-more': 'frontend.ad.table-information-value-rooms_num-10-or-more',
    'rooms_num::more': 'frontend.ad.table-information-value-rooms_num-more',
    'security::alarm_system': 'frontend.ad.table-information-value-security-alarm_system',
    'security::closed_area': 'frontend.ad.table-information-value-security-closed_area',
    'security::monitoring': 'frontend.ad.table-information-value-security-monitoring',
    'security::security_vigilance': 'frontend.ad.table-information-value-security-security_vigilance',
    'security::smoke_detector': 'frontend.ad.table-information-value-security-smoke_detector',
    'security_types::alarm': 'frontend.ad.table-information-value-security_types-alarm',
    'security_types::anti_burglary_door': 'frontend.ad.table-information-value-security_types-anti_burglary_door',
    'security_types::closed_area': 'frontend.ad.table-information-value-security_types-closed_area',
    'security_types::entryphone': 'frontend.ad.table-information-value-security_types-entryphone',
    'security_types::monitoring': 'frontend.ad.table-information-value-security_types-monitoring',
    'security_types::roller_shutters': 'frontend.ad.table-information-value-security_types-roller_shutters',
    share: 'frontend.ad.table-information-value-share',
    'social_facilities::0': 'frontend.ad.table-information-value-social_facilities-0',
    'social_facilities::1': 'frontend.ad.table-information-value-social_facilities-1',
    'state::in_building': 'frontend.ad.table-information-value-state-in_building',
    'state::not_started': 'frontend.ad.table-information-value-state-not_started',
    'state::ready': 'frontend.ad.table-information-value-state-ready',
    'street::parking': 'frontend.ad.table-information-value-street-parking',
    'street::private-parking': 'frontend.ad.table-information-value-street-private-parking',
    'structure::103-brick': 'frontend.ad.table-information-value-structure-103-brick',
    'structure::103-wood': 'frontend.ad.table-information-value-structure-103-wood',
    'structure::45-brick': 'frontend.ad.table-information-value-structure-45-brick',
    'structure::45-wood': 'frontend.ad.table-information-value-structure-45-wood',
    'structure::glass': 'frontend.ad.table-information-value-structure-glass',
    'structure::shed': 'frontend.ad.table-information-value-structure-shed',
    'structure::steel': 'frontend.ad.table-information-value-structure-steel',
    'structure::tent': 'frontend.ad.table-information-value-structure-tent',
    'structure::tin': 'frontend.ad.table-information-value-structure-tin',
    terrace: 'frontend.ad.table-information-value-terrace',
    'tiled::stove': 'frontend.ad.table-information-value-tiled-stove',
    'type::agricultural': 'frontend.ad.table-information-value-type-agricultural',
    'type::agricultural_building': 'frontend.ad.table-information-value-type-agricultural_building',
    'type::building': 'frontend.ad.table-information-value-type-building',
    'type::commercial': 'frontend.ad.table-information-value-type-commercial',
    'type::habitat': 'frontend.ad.table-information-value-type-habitat',
    'type::other': 'frontend.ad.table-information-value-type-other',
    'type::recreational': 'frontend.ad.table-information-value-type-recreational',
    'type::woodland': 'frontend.ad.table-information-value-type-woodland',
    'underground::parking': 'frontend.ad.table-information-value-underground-parking',
    urban: 'frontend.ad.table-information-value-urban',
    'use_types-135::office': 'frontend.ad.table-information-value-use_types-135-office',
    'use_types-25::office': 'frontend.ad.table-information-value-use_types-25-office',
    'use_types::commercial': 'frontend.ad.table-information-value-use_types-commercial',
    'use_types::gastronomy': 'frontend.ad.table-information-value-use_types-gastronomy',
    'use_types::hotel': 'frontend.ad.table-information-value-use_types-hotel',
    'use_types::industrial': 'frontend.ad.table-information-value-use_types-industrial',
    'use_types::manufacturing': 'frontend.ad.table-information-value-use_types-manufacturing',
    'use_types::office': 'frontend.ad.table-information-value-use_types-office',
    'use_types::retail': 'frontend.ad.table-information-value-use_types-retail',
    'use_types::services': 'frontend.ad.table-information-value-use_types-services',
    'use_types::stock': 'frontend.ad.table-information-value-use_types-stock',
    'vicinity_types::forest': 'frontend.ad.table-information-value-vicinity_types-forest',
    'vicinity_types::lake': 'frontend.ad.table-information-value-vicinity_types-lake',
    'vicinity_types::mountains': 'frontend.ad.table-information-value-vicinity_types-mountains',
    'vicinity_types::open_terrain': 'frontend.ad.table-information-value-vicinity_types-open_terrain',
    'vicinity_types::sea': 'frontend.ad.table-information-value-vicinity_types-sea',
    'video::meeting': 'frontend.ad.table-information-value-video-meeting',
    'video::presentation': 'frontend.ad.table-information-value-video-presentation',
    'windows_type::aluminium': 'frontend.ad.table-information-value-windows_type-aluminium',
    'windows_type::notany': 'frontend.ad.table-information-value-windows_type-notany',
    'windows_type::plastic': 'frontend.ad.table-information-value-windows_type-plastic',
    'windows_type::wooden': 'frontend.ad.table-information-value-windows_type-wooden',
    y: 'frontend.ad.table-information-value-y',
};
/* eslint-enable no-restricted-syntax */

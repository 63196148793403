import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Boxes = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 16px;

    &::after {
        content: '';
        width: 100%;
        padding-bottom: 16px;
        border-bottom: 1px solid;
        border-bottom-color: ${({ theme }): string => theme.deprecated.domains.ad.adSectionHeading.borderColor};
    }
`;

export const BoxContent = styled.div`
    font-size: ${SIZE.h5};
    font-weight: ${WEIGHT.semibold};

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.h4};
    }
`;

export const NumberOfUnitsInProject = styled.span`
    font-weight: ${WEIGHT.regular};
`;

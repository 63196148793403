import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const ContactPersonBannerSkeleton = styled(Skeleton)`
    height: 172px;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 72px;
    }
`;

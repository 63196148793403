import {
    ArrowIcon,
    CenteredButton,
} from '@domains/ad/components/ProjectPage/components/ScrollToAdsListButton/ScrollToAdsListButton.theme';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import type { JSX, RefObject } from 'react';

interface Props {
    adsListRef: RefObject<HTMLDivElement>;
}

export const ScrollToAdsListButton = ({ adsListRef }: Props): JSX.Element => {
    const [t] = useTranslations();

    const handleClick = (): void => {
        const adsListComponent = adsListRef.current;

        if (!adsListComponent) return;

        const headerOffset = 100;
        const offsetPosition = adsListComponent.offsetTop - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    };

    return (
        <CenteredButton variant={ButtonVariant.Ghost} onClick={handleClick}>
            {t('frontend.project-page.scroll-to-ad-list-button')} <ArrowIcon icon={faArrowUp} />
        </CenteredButton>
    );
};

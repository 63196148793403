import { LogoWrapper } from '@domains/ad/components/ProjectPage/components/LogoWrapper/LogoWrapper.theme';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';

import { LogoImg } from './DeveloperLogo.theme';

interface Props {
    url?: string;
    imageUrl: string | undefined;
    name?: string;
    brandingVisible: boolean | undefined;
}

export const DeveloperLogo = ({ url, imageUrl, name, brandingVisible }: Props): JSX.Element | null => {
    const { trackEvent } = useTracking();
    const handleLogoClick = (): void => {
        trackEvent('seller_logo_click');
    };

    if (!imageUrl || !brandingVisible) {
        return null;
    }

    return (
        <LogoWrapper href={url} onClick={handleLogoClick}>
            <LogoImg src={imageUrl} alt={name} />
        </LogoWrapper>
    );
};

import { Button } from '@domains/shared/components/Button/Button';
import { TextButton } from '@domains/shared/components/TextButton/TextButton';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LAYER } from '@lib/styles/partials/layers';
import { WEIGHT } from '@lib/styles/partials/typography';

export const Form = styled.form<{ isNewProjectPage?: boolean }>`
    display: grid;
    grid-template-areas:
        'price'
        'area'
        'rooms'
        'clear'
        'submit';
    margin-top: ${({ isNewProjectPage }): string => (isNewProjectPage ? '0' : '48px')};
    margin-bottom: ${({ isNewProjectPage }): string => (isNewProjectPage ? '32px' : '0')};

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-areas:
            'price area rooms submit'
            'clear . . .';
        grid-template-columns: 1fr 1fr 160px 120px;
        align-items: end;
    }
`;

const InputWrapper = styled.div<{ shouldAddSeparator?: boolean }>`
    & > div {
        margin-top: 8px;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        padding-left: 8px;
        & > div {
            padding-right: ${({ shouldAddSeparator }): string => (shouldAddSeparator ? '8px' : '0')};
            border-right: ${({ shouldAddSeparator }): string => (shouldAddSeparator ? '1px solid' : '0')};
            border-right-color: ${({ theme, shouldAddSeparator }): string =>
                shouldAddSeparator
                    ? theme.deprecated.domains.search.searchForm.fieldSectionSeparator.borderColor
                    : 'transparent'};
        }
    }
`;

export const PriceContainer = styled.div<{ isNewProjectPage?: boolean }>`
    grid-area: price;

    @media (min-width: ${BREAKPOINT.md}) {
        padding-right: ${({ isNewProjectPage }): string => (isNewProjectPage ? '8px' : '0')};
        padding-left: ${({ isNewProjectPage }): string => (isNewProjectPage ? '0' : '8px')};
    }
`.withComponent(InputWrapper);

export const AreaContainer = styled.div<{ isNewProjectPage?: boolean }>`
    grid-area: area;

    @media (min-width: ${BREAKPOINT.md}) {
        padding-left: ${({ isNewProjectPage }): string => (isNewProjectPage ? '0' : '8px')};
    }
`.withComponent(InputWrapper);

export const RoomsContainer = styled.div<{ isNewProjectPage?: boolean }>`
    z-index: ${LAYER.x1};
    grid-area: rooms;

    @media (min-width: ${BREAKPOINT.md}) {
        padding-right: ${({ isNewProjectPage }): string => (isNewProjectPage ? '8px' : '0')};
    }
`.withComponent(InputWrapper);

export const ClearCriteriaButton = styled(TextButton)<{ isNewProjectPage?: boolean }>`
    grid-area: clear;
    width: 50%;
    padding: ${({ isNewProjectPage }): string => (isNewProjectPage ? '16px 0 0 0' : '16px 0 0 8px')};
    text-align: end;
    justify-self: end;

    @media (min-width: ${BREAKPOINT.md}) {
        text-align: start;
        justify-self: start;
    }
`;

export const InputLabel = styled.label`
    font-weight: ${WEIGHT.bold};
`;

export const SubmitButton = styled(Button)<{ isNewProjectPage?: boolean }>`
    display: flex;
    grid-area: submit;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    width: 100%;
    min-height: 36px;
    margin-top: 24px;

    @media (min-width: ${BREAKPOINT.md}) {
        margin-top: 0;
        margin-left: ${({ isNewProjectPage }): string => (isNewProjectPage ? '0' : '8px')};
    }
`;

import type { Lookup } from '@domains/ad/types/adInvestmentUnits';
import type { SortingParams } from '@domains/ad/types/sorting/sortingParams';

export const PAGE_SIZE_OPTIONS = [5, 10, 20, 50];
export const PAGE_SIZE_DEFAULT = PAGE_SIZE_OPTIONS[0];

const INVESTMENT_UNITS_PAGE_SIZE = 10;
const DEFAULT_INVESTMENT_UNITS_SORTING_PARAMS = {
    by: 'PricePerMeter',
    direction: 'asc',
} as SortingParams;

export const DEFAULT_INVESTMENT_UNITS_LOOKUP: Lookup = {
    page: 1,
    pageSize: INVESTMENT_UNITS_PAGE_SIZE,
    sort: DEFAULT_INVESTMENT_UNITS_SORTING_PARAMS,
    withFacets: false,
};

const PROJECT_UNITS_PAGE_SIZE = 5;
const DEFAULT_PROJECT_UNITS_SORTING_PARAMS = {
    by: 'Price',
    direction: 'asc',
} as SortingParams;

export const DEFAULT_PROJECT_UNITS_LOOKUP: Lookup = {
    page: 1,
    pageSize: PROJECT_UNITS_PAGE_SIZE,
    sort: DEFAULT_PROJECT_UNITS_SORTING_PARAMS,
    withFacets: false,
};

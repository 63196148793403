import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';
import { SIZE } from '@lib/styles/partials/typography';
import { theme } from '@lib/styles/themes/nexus';

interface IsExpandedProps {
    isExpanded: boolean;
    optionsLength: number;
}

const ELEMENT_HEIGHT = 40;
const BORDER_WIDTH = 1;

export const ShadowWrapper = styled.div<IsExpandedProps>`
    display: ${({ isExpanded }): string => (isExpanded ? 'block' : 'none')};
    position: absolute;
    height: ${({ optionsLength }): string => `calc(100% + ${optionsLength * ELEMENT_HEIGHT}px + ${BORDER_WIDTH}px)`};
    border-radius: 2px;
    box-shadow: ${({ theme }): string => theme.deprecated.domains.search.dropdownWithCheckboxes.expanded.boxShadow};
    inset: 0 0 auto;
`;

export const Wrapper = styled.div`
    position: relative;
`;

export const DropdownValueWrapper = styled.div<IsExpandedProps & { hasOptionsSelected: boolean }>`
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    min-height: 40px;
    padding: 7px 35px 7px 12px;
    border: ${BORDER_WIDTH}px solid;
    border-radius: ${({ isExpanded }): string => (isExpanded ? '2px 2px 0px 0px' : '2px')};
    border-color: ${({ theme, isExpanded }): string =>
        isExpanded
            ? theme.deprecated.domains.search.dropdownWithCheckboxes.hover.borderColor
            : theme.deprecated.domains.search.dropdownWithCheckboxes.base.borderColor};
    background-color: ${({ hasOptionsSelected, theme }): string =>
        hasOptionsSelected
            ? theme.deprecated.domains.search.dropdownWithCheckboxes.selected.backgroundColor
            : theme.deprecated.domains.search.dropdownWithCheckboxes.base.backgroundColor};
    color: ${({ theme }): string => theme.deprecated.domains.search.dropdownWithCheckboxes.base.color};
    font-size: ${SIZE.p3};
    cursor: pointer;
    gap: 4px;

    &:hover {
        border-color: ${({ theme }): string =>
            theme.deprecated.domains.search.dropdownWithCheckboxes.hover.borderColor};
    }
`;

export const Placeholder = styled.span`
    opacity: 0.6;
`;

export const ValueChip = styled.span`
    display: flex;
    position: relative;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    padding-right: 24px;
    border-radius: 2px;
    background: ${theme.colors.$03_gray};
    font-size: ${SIZE.p4};
`;

export const ValueString = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const RemoveSelectedOptionButton = styled.button`
    position: absolute;
    overflow: hidden;
    border: none;
    background: transparent;
    cursor: pointer;
    inset: 0 0 0 auto;

    &:hover {
        background: ${theme.colors.$02_red_light};
        color: ${theme.colors.$05_red_primary};
    }
`;

export const IconWrapper = styled.div<Pick<IsExpandedProps, 'isExpanded'>>`
    position: absolute;
    top: 9.5px;
    right: 8px;
    transform: ${({ isExpanded }): string => (isExpanded ? 'rotate(-180deg)' : 'rotate(0)')};

    svg {
        display: block;
    }
`;

export const OptionsList = styled.ul`
    position: absolute;
    z-index: ${LAYER.x0};
    margin: 0;
    padding: 0;
    list-style-type: none;
    transform: translate(0, 100%);
    border: ${BORDER_WIDTH}px solid;
    border-color: ${({ theme }): string => theme.deprecated.domains.search.dropdownWithCheckboxes.hover.borderColor};
    background: ${({ theme }): string => theme.deprecated.domains.search.dropdownWithCheckboxes.base.backgroundColor};
    inset: auto 0 ${BORDER_WIDTH}px 0;
`;

export const OptionListItem = styled.li`
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 16px;

    &:hover {
        background-color: ${({ theme }): string =>
            theme.deprecated.domains.search.dropdownWithCheckboxes.option.hover.backgroundColor};
    }
`;

export const Label = styled.label`
    width: 100%;
    padding-left: 8px;
`;

export const CloseButton = styled.button`
    display: flex;
    position: absolute;
    top: 10px;
    right: 8px;
    padding: 0;
    border: 0;
    background: none;
    color: ${({ theme }): string => theme.deprecated.domains.search.dropdownWithCheckboxes.closeIcon.color};
    cursor: pointer;
`;

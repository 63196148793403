import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE } from '@lib/styles/partials/typography';

export const Banner = styled.p`
    display: flex;
    align-items: center;
    margin-top: 32px;
    padding: 15px 0 15px 20px;
    border-radius: 4px;
    background-color: ${({ theme }): string => theme.deprecated.domains.ad.adLeaderBanner.backgroundColor};
    font-size: ${SIZE.p3};

    @media (min-width: ${BREAKPOINT.xs}) {
        display: inline-flex;
        padding-right: 30px;
        padding-left: 30px;
        font-size: ${SIZE.p2};
    }
`;

export const Label = styled.strong`
    display: inline-block;
`;

export const Icon = styled.img`
    width: 32px;
    height: 28px;
    margin-right: 15px;
`;

import { AdSectionHeading } from '@domains/ad/components/AdSectionHeading/AdSectionHeading';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useRef } from 'react';

import { Container, ProjectPageDescriptionWrapper } from './ProjectPageDescription.theme';

interface Props {
    content: string;
}

export const ProjectPageDescription = ({ content }: Props): JSX.Element => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const endDescriptionIntersector = useRef(null);
    const hasScrolledDescription = useRef(false);

    const description = (
        <ProjectPageDescriptionWrapper
            data-cy="projectPageAdDescription"
            dangerouslySetInnerHTML={{ __html: content }}
        />
    );

    useIntersection(
        endDescriptionIntersector,
        (_, observer) => {
            if (hasScrolledDescription.current) {
                return observer?.disconnect();
            }

            trackEvent('ad_description_end_scroll');

            hasScrolledDescription.current = true;
            observer?.disconnect();
        },
        { threshold: 0, shouldRunOnIntersectionOnly: true },
    );

    return (
        <Container role="region">
            <AdSectionHeading isBorderless>{t('frontend.ad.description.title')}</AdSectionHeading>
            {description}
            <span id="projectPageDescriptionEndIntersector" ref={endDescriptionIntersector} />
        </Container>
    );
};

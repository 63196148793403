import type { RecommendedAd, UserAd } from '@domains/ad/types/Ad';
import { rejectPartialObject } from '@domains/shared/helpers/rejectPartialObject';
import type { AdvertAgency } from '@type/ad/advertAgency';
import type { AdvertCategory } from '@type/ad/attributes/advertCategory';
import dynamic from 'next/dynamic';
import type { JSX, RefObject } from 'react';

const LazyUserAdsSection = dynamic(() => import('@domains/ad/components/UserAdsSection/UserAdsSection'));
const LazyRecommendedAdsSection = dynamic(
    () => import('@domains/ad/components/RecommendedAdsSection/RecommendedAdsSection'),
);

interface Props {
    agency: AdvertAgency;
    recommendedAdverts: RecommendedAd[];
    userAdverts: UserAd[];
    adCategory: AdvertCategory;
    userRecommendedAdsRef?: RefObject<HTMLDivElement>;
}

export const RecommendedAdsAdContentWrapper = ({
    agency,
    recommendedAdverts,
    userAdverts,
    adCategory,
    userRecommendedAdsRef,
}: Props): JSX.Element => {
    const hasRecommendations = recommendedAdverts.length > 0;
    const hasUserAdverts = userAdverts.length > 0;

    return (
        <>
            {hasRecommendations ? (
                <LazyRecommendedAdsSection adverts={recommendedAdverts} data-cy="search.recommended-ads-section" />
            ) : null}

            {hasUserAdverts ? (
                <div ref={userRecommendedAdsRef}>
                    <LazyUserAdsSection
                        adverts={userAdverts.slice(0, 4)}
                        agency={rejectPartialObject({
                            id: agency?.id,
                            name: agency?.name,
                            type: agency?.type,
                            url: agency?.url,
                        })}
                        adCategory={adCategory}
                    />
                </div>
            ) : null}
        </>
    );
};

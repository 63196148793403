import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';

const ROOMS_NUMBER = {
    one: 'frontend.ad.project-units-list.rooms-num-1',
    two: 'frontend.ad.project-units-list.rooms-num-2',
    three: 'frontend.ad.project-units-list.rooms-num-3',
    four: 'frontend.ad.project-units-list.rooms-num-4',
    five: 'frontend.ad.project-units-list.rooms-num-5',
    six: 'frontend.ad.project-units-list.rooms-num-6',
    seven: 'frontend.ad.project-units-list.rooms-num-7',
    eight: 'frontend.ad.project-units-list.rooms-num-8',
    nine: 'frontend.ad.project-units-list.rooms-num-9',
    more: 'frontend.ad.project-units-list.rooms-num-10',
};

export const getInvestmentsFilterOptions = (
    t: Translator,
): {
    roomsNumber: { label: string; value: string }[];
} => {
    return {
        roomsNumber: Object.entries(ROOMS_NUMBER).map(([value, label]) => ({
            value: value.toUpperCase(),
            label: t(label),
        })),
    };
};

import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

export const LogoWrapper = styled.a`
    display: none;
    grid-area: logo;

    @media (min-width: ${BREAKPOINT.sm}) {
        display: block;
        justify-self: end;
    }
`;

import { SITE_CONFIG } from '@config/siteConfig';
import { getInvestmentsFilterOptions } from '@domains/ad/helpers/getInvestmentsFilterOptions';
import { DropdownWithCheckboxes } from '@domains/shared/components/DropdownWithCheckboxes/DropdownWithCheckboxes';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { getRoomNumberByStringValue } from '@domains/shared/helpers/getRoomNumberByTheString';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { JSX } from 'react';
import { useCallback, useContext, useMemo } from 'react';

import { Container, RoomsLabel } from './RoomsField.theme';

interface Props {
    placeholder?: string;
    title?: string;
    isRenderedInAdPageInvestment?: boolean;
}

export const RoomsField = ({
    placeholder = 'frontend.search.rooms-field.label',
    title = 'frontend.search.rooms-field.label',
    isRenderedInAdPageInvestment = false,
}: Props): JSX.Element => {
    const { shouldUseTypologyInsteadOfNumberOfRooms } = SITE_CONFIG;
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { isDesktop } = useContext(RWDContext);

    const roomsOptions = useMemo(() => getInvestmentsFilterOptions(t).roomsNumber, [t]);

    const trackingTouchPointPage = isRenderedInAdPageInvestment ? 'project_page' : 'listing';

    const trackOnRoomsSelect = useCallback(
        (roomsNumber: string[]): void => {
            const trackingRoomsNumber = roomsNumber
                ? (roomsNumber as RoomsNumber[]).map(getRoomNumberByStringValue)
                : [];

            trackEvent('nb_rooms_selected', {
                nb_rooms: trackingRoomsNumber,
                touch_point_page: trackingTouchPointPage,
            });
        },

        [trackingTouchPointPage, trackEvent],
    );

    const trackOnRoomsExpand = useCallback((): void => {
        trackEvent('nb_rooms_click', { touch_point_page: trackingTouchPointPage });
    }, [trackingTouchPointPage, trackEvent]);

    return (
        <Container>
            {isRenderedInAdPageInvestment || !isDesktop ? (
                <RoomsLabel>{t('frontend.search.rooms-field.mobile-label')}</RoomsLabel>
            ) : null}

            <DropdownWithCheckboxes
                id={SEARCH_FORM_UNIVERSAL_FIELD.roomsNumber}
                name={SEARCH_FORM_UNIVERSAL_FIELD.roomsNumber}
                options={roomsOptions}
                placeholder={t(placeholder)}
                title={shouldUseTypologyInsteadOfNumberOfRooms ? undefined : t(title)}
                trackingOnDropdownExpanded={trackOnRoomsExpand}
                trackOnSelect={trackOnRoomsSelect}
                cyCheckboxLabel="search.rooms-field.option"
                cyLabel="search.rooms-field.label"
                cyButton="search.rooms-field.button"
            />
        </Container>
    );
};

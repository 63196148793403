import { Table } from '@domains/ad/components/Table/Table';
import type { Ad } from '@domains/ad/types/Ad';
import type { JSX } from 'react';

import { ADDITIONAL_INFORMATION_ICONS } from './projectAdditionalInformationIcons';

interface Props {
    ad: Ad;
}

export const ProjectAdditionalInformation = ({ ad }: Props): JSX.Element => {
    const { links, additionalInformation } = ad;

    return (
        <Table
            dataCy="project.additional-information.table"
            testId="project.additional-information.table"
            // eslint-disable-next-line no-restricted-syntax -- deserves its own MR
            translationPrefix="frontend.ad.table-information-"
            items={additionalInformation}
            advertLinks={links}
            icons={ADDITIONAL_INFORMATION_ICONS}
            isInvestment
            shouldRenderAskForInformation={true}
        />
    );
};

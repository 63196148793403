import AskForMissingInfoButton from '@domains/ad/components/Table/AskForMissingInfoButton';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { AdvertLinks } from '@type/ad/advertLinks';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { useState } from 'react';

import {
    Column,
    DescriptionContent,
    IconImg,
    ItemContainer,
    LabelContent,
    RemoteServices,
    StyledLinkTextButton,
    StyledTextButton,
    TooltipIconInfo,
} from './TableItem.theme';

const LazyExternalContentModal = dynamic(
    () => import('@domains/ad/components/ExternalContentModal/ExternalContentModal'),
    { ssr: false },
);

interface RemoteService {
    label: string;
    media: {
        url: string;
        shouldOpenInNewTab?: boolean;
    };
    trackingEventName?: string;
}

const getNormalizedRemoteServices = (links?: AdvertLinks): RemoteService[] => {
    const { videoUrl, view3dUrl, walkaroundUrl } = links || {};
    const virtualWalkUrl = walkaroundUrl || view3dUrl;
    const result: RemoteService[] = [];

    if (videoUrl) {
        result.push({
            label: 'frontend.ad.remote-service.movie',
            media: { url: videoUrl },
            trackingEventName: 'video_click',
        });
    }

    if (virtualWalkUrl) {
        result.push({
            label: 'frontend.ad.remote-service.virtual-walk',
            media: { url: virtualWalkUrl },
            trackingEventName: '3d_view_click',
        });
    }

    return result;
};

interface Props {
    icon?: string;
    advertLinks?: AdvertLinks;
    title: string;
    isRemoteServicesFlagActive: boolean;
    shouldRenderAskForInformation?: boolean;
    touchPointButtonForMissingInfo: string;
}

export const RemoteServicesTableItem = ({
    icon,
    advertLinks,
    title,
    isRemoteServicesFlagActive,
    shouldRenderAskForInformation,
    touchPointButtonForMissingInfo,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const [iframeOverlayUrl, setIframeOverlayUrl] = useState('');
    const normalizedRemoteServices = getNormalizedRemoteServices(advertLinks);
    const hasRemoteServices = normalizedRemoteServices.length > 0;
    const hasData = hasRemoteServices || isRemoteServicesFlagActive;
    const shouldGreyedOut = !shouldRenderAskForInformation && !hasData;
    const onOverlayDismiss = (): void => setIframeOverlayUrl('');

    return (
        <ItemContainer aria-label={title} hasIcon={!!icon} isRemoteServiceList={hasRemoteServices} role="region">
            {icon ? <IconImg hasData={hasData} src={icon} alt="" /> : null}
            <Column shouldGreyedOut={shouldGreyedOut}>
                <LabelContent data-cy="table-label-content">{title}</LabelContent>
            </Column>
            <Column shouldGreyedOut={shouldGreyedOut}>
                {hasRemoteServices ? (
                    <>
                        <RemoteServices isRemoteServicesFlagActive={isRemoteServicesFlagActive}>
                            {normalizedRemoteServices.map(({ label, media, trackingEventName }) =>
                                media.shouldOpenInNewTab ? (
                                    <StyledLinkTextButton
                                        key={label}
                                        href={media.url}
                                        target="_blank"
                                        isInline
                                        onClick={(): void => {
                                            if (trackingEventName) {
                                                trackEvent(trackingEventName, {
                                                    touch_point_page: 'ad_page',
                                                    touch_point_button: 'top_information',
                                                });
                                            }
                                        }}
                                    >
                                        {t(label)}
                                    </StyledLinkTextButton>
                                ) : (
                                    <StyledTextButton
                                        key={label}
                                        type="button"
                                        isInline
                                        onClick={(): void => {
                                            setIframeOverlayUrl(media.url);

                                            if (trackingEventName) {
                                                trackEvent(trackingEventName, {
                                                    touch_point_page: 'ad_page',
                                                    touch_point_button: 'top_information',
                                                });
                                            }
                                        }}
                                    >
                                        {t(label)}
                                    </StyledTextButton>
                                ),
                            )}
                        </RemoteServices>
                        <LazyExternalContentModal
                            shouldDisplayOverlay={!!iframeOverlayUrl}
                            onOverlayDismiss={onOverlayDismiss}
                            iframeOverlayUrl={iframeOverlayUrl}
                        />
                    </>
                ) : null}
                {!hasRemoteServices && isRemoteServicesFlagActive ? (
                    <DescriptionContent hasData={true}>
                        {t('frontend.ad.table-information-value-remote_services-1')}
                    </DescriptionContent>
                ) : null}
                {hasData ? null : <AskForMissingInfoButton t={t} touchPointButton={touchPointButtonForMissingInfo} />}
                {isRemoteServicesFlagActive ? (
                    <TooltipIconInfo description={t('frontend.ad-overview.table.remote-services-info-tooltip')} />
                ) : null}
            </Column>
        </ItemContainer>
    );
};

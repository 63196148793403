import { AdSectionHeading } from '@domains/ad/components/AdSectionHeading/AdSectionHeading';
import type { Ad } from '@domains/ad/types/Ad';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { getRelativeTime } from '@lib/time';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';

import { Container, DateModified, DatePublished, OfferId, ReferenceId } from './AdMeta.theme';

interface Props {
    ad: Ad;
}

export const AdMeta = ({ ad }: Props): JSX.Element | null => {
    const { createdAt, modifiedAt, id, externalId, referenceId } = ad;
    const { lang } = useSiteSettings();
    const [t] = useTranslations();
    const [relativeDatePublished, setRelativeDatePublished] = useState<string>();
    const [relativeDateModified, setRelativeDateModified] = useState<string>();

    useEffect(() => {
        const from = new Date();
        const dateCreatedAt = new Date(createdAt);
        const dateModifiedAt = new Date(modifiedAt);

        const getRelativeDates = async (): Promise<void> => {
            const relativeDatePublished = await getRelativeTime({ pastDate: dateCreatedAt, from, lang });
            const relativeDateModified = await getRelativeTime({ pastDate: dateModifiedAt, from, lang });
            setRelativeDatePublished(relativeDatePublished);
            setRelativeDateModified(relativeDateModified);
        };

        getRelativeDates();
    }, [createdAt, modifiedAt, lang]);

    if (!relativeDatePublished || !relativeDateModified) {
        return null;
    }

    return (
        <>
            <AdSectionHeading>{null}</AdSectionHeading>
            <Container>
                <OfferId>{`${t('frontend.ad.meta.offer-id')} ${id}`}</OfferId>
                <ReferenceId>
                    {referenceId || externalId
                        ? `${t('frontend.ad.meta.external-id')} ${referenceId || externalId}`
                        : null}
                </ReferenceId>
                <DatePublished>{`${t('frontend.ad.meta.date-published')} ${relativeDatePublished}`}</DatePublished>
                <DateModified>{`${t('frontend.ad.meta.date-modified')} ${relativeDateModified}`}</DateModified>
            </Container>
        </>
    );
};

import { Checkbox } from '@domains/shared/components/Checkbox/Checkbox';
import { ChevronIcon, CloseIcon } from '@domains/shared/components/DropdownWithCheckboxes/Icon';
import type { DropdownOption } from '@type/search/dropdownOption';
import type { FilterOption as Option } from '@type/search/filters/option';
import type { JSX, MouseEvent } from 'react';
import { useCallback } from 'react';

import { DropdownValue } from './components/DropdownValue/DropdownValue';
import {
    CloseButton,
    DropdownValueWrapper,
    IconWrapper,
    Label,
    OptionListItem,
    OptionsList,
    ShadowWrapper,
    Wrapper,
} from './DropdownWithCheckboxes.theme';
import { useDropdownWithCheckboxes } from './hooks/useDropdownWithCheckboxes';

interface Props {
    id: string;
    name: string;
    options: ReadonlyArray<Option> & ReadonlyArray<DropdownOption>;
    cyButton?: string;
    cyCheckboxLabel?: string;
    cyLabel?: string;
    cyList?: string;
    placeholder?: string;
    title?: string;
    withChips?: boolean;
    trackingOnDropdownExpanded?: () => void;
    trackOnClear?: () => void;
    trackOnChipClear?: (data: { name: string; value: string }) => void;
    trackOnSelect?: (value: string[]) => void;
}

export const DropdownWithCheckboxes = ({
    id,
    name,
    options,
    cyButton = 'button',
    cyCheckboxLabel = 'checkboxLabel',
    cyLabel = 'label',
    cyList = 'list',
    placeholder,
    title,
    withChips = false,
    trackingOnDropdownExpanded = (): void => undefined,
    trackOnClear = (): void => undefined,
    trackOnChipClear = (): void => undefined,
    trackOnSelect = (): void => undefined,
}: Props): JSX.Element => {
    const {
        dropdownRef,
        fieldValue,
        isDropdownExpanded,
        pickedOptions,
        handleCheckboxChange,
        setIsDropdownExpanded,
        setValue,
    } = useDropdownWithCheckboxes({ name, options, withChips, trackingOnDropdownExpanded, trackOnSelect });

    const handleChipsDelete = (event: MouseEvent, value: string): void => {
        event.preventDefault();
        event.stopPropagation();

        const currentFieldValues: string[] = fieldValue ?? [];

        if (currentFieldValues.includes(value)) {
            setValue(
                name,
                currentFieldValues.filter((valuea) => valuea !== value),
                { shouldDirty: true },
            );

            trackOnChipClear?.({ name, value });
        }
    };

    const handleCloseButtonClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            trackOnClear?.();
            setValue(name, []);
        },
        [name, setValue, trackOnClear],
    );

    return (
        <Wrapper ref={dropdownRef} id={id} data-cy={cyButton}>
            <ShadowWrapper isExpanded={isDropdownExpanded} optionsLength={options.length} />
            <DropdownValueWrapper
                role="combobox"
                aria-haspopup="listbox"
                hasOptionsSelected={!withChips && !!pickedOptions}
                isExpanded={isDropdownExpanded}
                optionsLength={options.length}
                onClick={(): void => setIsDropdownExpanded((state) => !state)}
                data-cy={cyLabel}
            >
                <DropdownValue
                    pickedOptions={pickedOptions}
                    placeholder={placeholder}
                    withChips={withChips}
                    title={title}
                    onChipsDelete={handleChipsDelete}
                />

                {withChips || (!withChips && !pickedOptions) ? (
                    <IconWrapper isExpanded={isDropdownExpanded}>
                        <ChevronIcon />
                    </IconWrapper>
                ) : null}

                {!withChips && pickedOptions ? (
                    <CloseButton type="button" onClick={handleCloseButtonClick}>
                        <CloseIcon />
                    </CloseButton>
                ) : null}
            </DropdownValueWrapper>

            {isDropdownExpanded ? (
                <OptionsList data-cy={cyList}>
                    {options.map((item) => {
                        const itemIdentifier = `dropdown-checkbox-${name}-${item.value}`;

                        return (
                            <OptionListItem key={itemIdentifier} role="menuitem">
                                <Checkbox
                                    variant={withChips ? 'default' : 'primary'}
                                    id={itemIdentifier}
                                    value={item.value}
                                    onChange={handleCheckboxChange}
                                    checked={fieldValue?.includes(item.value) ?? false}
                                />
                                <Label data-cy={`${cyCheckboxLabel}-${item.value}`} htmlFor={itemIdentifier}>
                                    {item.label}
                                </Label>
                            </OptionListItem>
                        );
                    })}
                </OptionsList>
            ) : null}
        </Wrapper>
    );
};

import { AdHeadMetadata } from '@domains/ad/components/AdHeadMetadata/AdHeadMetadata';
import type { OpenGalleryFunction } from '@domains/ad/components/AdMediaGallery/openGalleryRef';
import { ProjectPage as ProjectPageContent } from '@domains/ad/components/ProjectPage/ProjectPage';
import { AdPageModalContext, AdPageModalContextProvider } from '@domains/ad/contexts/AdPageModalContext';
import { ReportAdModalContextProvider } from '@domains/ad/contexts/ReportAdModalContext';
import { NexusInvestmentPageContent } from '@domains/ad/nexusComponents/NexusInvestmentPageContent/NexusInvestmentPageContent';
import type { PagePropsBase } from '@domains/ad/types/PagePropsBase';
import { SavedAdsContextProvider } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { getSpecialOfferTypeTrackingValue } from '@domains/shared/helpers/getSpecialOfferTypeTrackingValue';
import { useFavoritesSubscriptionHash } from '@domains/shared/hooks/useFavoritesSubscriptionHash/useFavoritesSubscriptionHash';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { FontProvider } from '@domains/shared/theme/FontProvider';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import { withGraphQLClient } from '@lib/graphql/withGraphQLClient';
import { MainLayout } from '@lib/layouts/MainLayout/MainLayout';
import { PageProviders } from '@lib/pages/contexts/PageProviders/PageProviders';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { useOnWindowResize } from '@lib/utils/useOnWindowResize';
import type { JSX } from 'react';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { ExpiredAdPage } from './ExpiredAdPage';

export type ProjectPageProps = PagePropsBase;

const BaseProjectPage = ({ ad, relativeUrl = '', referer }: ProjectPageProps): JSX.Element => {
    const {
        lang,
        featureFlags: { isNexusInvestmentPageEnabled },
    } = useSiteSettings();
    const shouldShowBranding = !!ad.agency?.brandingVisible;

    const [isMobile, setIsMobile] = useState(true);
    const { modalVisibility, toggleModalState } = useContext(AdPageModalContext);

    const [isRedirectToLoginModalOpen, setIsRedirectToLoginModalOpen] = useState(false);

    const onResize = useCallback(() => {
        setIsMobile(!window.matchMedia(`(min-width: ${BREAKPOINT.md})`).matches);
    }, []);

    useOnWindowResize(onResize);

    const openGalleryRef = useRef<OpenGalleryFunction>(null);

    // adding ad to observed if needed after login
    useFavoritesSubscriptionHash();

    if (isNexusInvestmentPageEnabled) {
        return (
            <>
                <AdHeadMetadata ad={ad} relativeUrl={relativeUrl} lang={lang} />
                <MainLayout hasStickyPageHeader={false} shouldDisplayFooter>
                    <FontProvider>
                        <NexusInvestmentPageContent ad={ad} referer={referer} />
                    </FontProvider>
                </MainLayout>
            </>
        );
    }

    return (
        <ProjectPageContent
            ad={ad}
            relativeUrl={relativeUrl}
            showBranding={shouldShowBranding}
            isMobile={isMobile}
            isRedirectToLoginModalOpen={isRedirectToLoginModalOpen}
            modalVisibility={modalVisibility}
            openGalleryRef={openGalleryRef}
            referer={referer}
            setIsRedirectToLoginModalOpen={setIsRedirectToLoginModalOpen}
            toggleModalState={toggleModalState}
        />
    );
};

const DEFAULT_TRACKING_DATA = {
    touch_point_page: 'ad_page',
} as const;

export const ProjectPageWithProviders = (props: ProjectPageProps & LaquesisPageProps): JSX.Element => {
    const trackingData = useMemo(
        () => ({
            ...DEFAULT_TRACKING_DATA,
            ...props.adTrackingData,
            special_offer_type: getSpecialOfferTypeTrackingValue(props.ad.specialOffer),
            discount_value: props.ad.specialOffer?.discountValue || null,
        }),
        [props],
    );

    const { shouldShowExpiredAdPage } = props.ad;

    return (
        <PageProviders
            defaultTrackingData={trackingData}
            experiments={props.experiments}
            laquesisResult={props.laquesisResult}
            trackPageName={shouldShowExpiredAdPage ? 'error_410' : 'ad_page'}
        >
            <SavedAdsContextProvider>
                <AdPageModalContextProvider>
                    <ReportAdModalContextProvider>
                        {shouldShowExpiredAdPage ? <ExpiredAdPage {...props} /> : <BaseProjectPage {...props} />}
                    </ReportAdModalContextProvider>
                </AdPageModalContextProvider>
            </SavedAdsContextProvider>
        </PageProviders>
    );
};

export const ProjectPage = withGraphQLClient({
    component: ProjectPageWithProviders,
});

import { AdSubscribeButton } from '@domains/ad/components/AdSubscribeButton/AdSubscribeButton';
import type { SubscribeButtonSection } from '@domains/ad/types/subscribeButtonSection';
import type { JSX } from 'react';

import { Container } from './SubscribeAdSection.theme';

interface Props {
    handleSubscribeButtonClick: (section: SubscribeButtonSection) => void;
    adId: number;
    shouldAddPadding?: boolean;
}

export const SubscribeAdSection = ({
    handleSubscribeButtonClick,
    adId,
    shouldAddPadding = true,
}: Props): JSX.Element => {
    return (
        <Container shouldAddPadding={shouldAddPadding}>
            <AdSubscribeButton
                adId={adId}
                handleSubscribeButtonClick={handleSubscribeButtonClick}
                section="SUBSCRIBE_AD_SECTION"
            />
        </Container>
    );
};

import { getMicrodataItems } from '@domains/ad/helpers/generateMicroData';
import type { AdBreadcrumb } from '@domains/ad/types/Ad';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX, MouseEventHandler } from 'react';
import { Fragment } from 'react';

import {
    ArrowRight,
    ArrowWrapper,
    BlankBreadcrumb,
    Breadcrumb,
    Container,
    StyledGoBackButton,
} from './AdBreadcrumbs.theme';
import { TRACKING_EXTRA_DATA } from './constants/tracking';

interface Props {
    breadcrumbs: AdBreadcrumb[];
    referer: string | null;
}

export const AdBreadcrumbs = ({ breadcrumbs, referer }: Props): JSX.Element => {
    const { trackEvent } = useTracking();

    const microdataItems = getMicrodataItems(breadcrumbs);

    const handleGoBackButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        trackEvent('breadcrumb_click', TRACKING_EXTRA_DATA);
    };

    const handleBreadcrumbClick: MouseEventHandler<HTMLAnchorElement> = () => {
        trackEvent('listing', TRACKING_EXTRA_DATA);
    };

    return (
        <Container data-testid="ad.breadcrumbs">
            <StyledGoBackButton
                referer={referer}
                textTranslationKey="frontend.breadcrumbs.go-back-to-list"
                data-cy="breadcrumb-go-back-button"
                onClick={handleGoBackButtonClick}
            />
            <Fragment>
                {breadcrumbs.map(({ label, url }, breadcrumbIndex) =>
                    url ? (
                        <Breadcrumb key={url} href={url} onClick={handleBreadcrumbClick}>
                            {/* We receive some street names with &quot; from GraphQL, so we need to replace it with " */}
                            {label.replace(/&quot;/g, '"')}
                            {breadcrumbIndex === breadcrumbs.length - 1 ? null : (
                                <ArrowWrapper>
                                    <ArrowRight icon={faChevronRight} height={8} width={5} />
                                </ArrowWrapper>
                            )}
                        </Breadcrumb>
                    ) : (
                        <BlankBreadcrumb key={label}>{label}</BlankBreadcrumb>
                    ),
                )}
                {breadcrumbs.length > 0 ? (
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(microdataItems) }}
                    />
                ) : null}
            </Fragment>
        </Container>
    );
};

import { StyledBadge } from '@domains/ad/components/SpecialOfferBadge/SpecialOfferBadge.theme';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import type { SpecialOffer } from '@type/ad/specialOffer';
import type { JSX } from 'react';

interface Props {
    specialOffer: SpecialOffer;
    className?: string;
}

const { tz } = getPublicEnvConfig();

export const SpecialOfferInfoDateBadge = ({ specialOffer, className }: Props): JSX.Element => {
    const { lang } = useSiteSettings();
    const [t] = useTranslations();

    const hasEndDate = specialOffer.details.endDate;
    const formattedEndDate = hasEndDate
        ? new Date(hasEndDate).toLocaleDateString(lang, {
              timeZone: tz,
          })
        : null;

    const specialOfferBadgeText = hasEndDate
        ? `${t('frontend.ad.ad-header.special-offer-till')} ${formattedEndDate}`
        : t('frontend.global.special-offer.title');

    return (
        <StyledBadge className={className} variant="default">
            {hasEndDate ? <Icon icon={faClock} /> : null}
            {specialOfferBadgeText}
        </StyledBadge>
    );
};

import 'react-image-gallery/styles/css/image-gallery.css';

import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import type { JSX } from 'react';

export const PlaceholderContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 -16px;

    @media (min-width: ${BREAKPOINT.md}) {
        margin: 0;
    }
`;

export const MainPicture = styled.picture`
    width: 100%;

    & > img {
        width: 100%;
        height: 350px;
        object-fit: cover;

        @media (min-width: ${BREAKPOINT.lg}) {
            height: 420px;
        }
    }

    @media (min-width: ${BREAKPOINT.lg}) {
        width: 898px;
    }
`;

export const ThumbnailSkeleton = (): JSX.Element => (
    <Skeleton height={134} width={252} style={{ display: 'block', margin: '0 0 8px 8px' }} />
);

export const ThumbnailsContainer = styled.div`
    display: none;
    flex-flow: column nowrap;
    width: 255px;
    overflow: hidden;

    @media (min-width: ${BREAKPOINT.lg}) {
        display: flex;
    }
`;

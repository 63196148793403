import { Label } from '@domains/shared/components/Label/Label';
import styled from '@emotion/styled';
import { WEIGHT } from '@lib/styles/partials/typography';

export const AreaLabel = styled(Label)`
    display: block;
    padding: 16px 0 8px;
    font-weight: ${WEIGHT.bold};
    text-align: left;
`;

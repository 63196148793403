import TagRegular from '@domains/ad/assets/tagRegular.svg';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { SpecialOffer } from '@type/ad/specialOffer';
import { SPECIAL_OFFER_VARIANT } from '@type/ad/specialOffer';
import type { JSX } from 'react';

import { StyledBadge } from './SpecialOfferBadge.theme';

interface Props {
    className?: string;
    shouldDisplayIcon?: boolean;
    specialOffer: SpecialOffer;
}

const SPECIAL_OFFERS_LABELS: Record<string, string> = {
    /* eslint-disable @typescript-eslint/naming-convention -- BE mapping reference */
    FreeParking: 'frontend.global.special-offer.free-parking',
    AttractivePaymentSchedule: 'frontend.global.special-offer.attractive-payment-plan',
    LastMinute: 'frontend.global.special-offer.last-minute',
    PreSale: 'frontend.global.special-offer.pre-sale',
    /* eslint-enable @typescript-eslint/naming-convention */
};

export const SpecialOfferBadge = ({ className, specialOffer, shouldDisplayIcon }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { __typename: specialOfferType, discountValue } = specialOffer;
    const isDiscountOffer = specialOfferType === SPECIAL_OFFER_VARIANT.priceDiscount;
    const discountedPercentage = isDiscountOffer ? discountValue : null;

    return (
        <StyledBadge className={className} variant="success" shouldReverseColors>
            {!isDiscountOffer && shouldDisplayIcon ? <TagRegular /> : null}
            <span>
                {isDiscountOffer
                    ? `- ${discountedPercentage}%`
                    : specialOfferType && t(SPECIAL_OFFERS_LABELS[specialOfferType])}
            </span>
        </StyledBadge>
    );
};

import { Button } from '@domains/shared/components/Button/Button';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';

export const CenteredButton = styled(Button)`
    display: block;
    margin: 0 auto;
`;

export const ArrowIcon = styled(Icon)`
    margin-left: 4px;
`;

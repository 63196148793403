import type { AdvertInvestmentFilters, SearchInvestmentFormTypes } from '@domains/ad/types/adInvestmentUnits';
import { getRoomNumberByStringValue } from '@domains/shared/helpers/getRoomNumberByTheString';
import { parseParameter } from '@domains/shared/helpers/parseParameter';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import { ROOMS_NUMBER_VARIANTS } from '@type/search/filters/roomsNumber';
import type { NextRouter } from 'next/router';

export const checkIfNumber = (valueFromQuery: string): boolean => !Number.isNaN(Number(valueFromQuery));

export const resetEmptyStringValueToNull = (params: Record<string, unknown>): void => {
    for (const key of Object.keys(params)) {
        if (params[key] === '') {
            params[key] = null;
        }
    }
};

const parseRoomNumbers = (roomsNumberFromQuery: string | string[] | null | undefined): string[] | null => {
    //1. roomsNumberFromQuery = '[FOUR]'
    if (!roomsNumberFromQuery || roomsNumberFromQuery.length === 0) {
        return null;
    }

    let parsedRoomsNumber = parseParameter(roomsNumberFromQuery, {
        multipleValueVariants: ROOMS_NUMBER_VARIANTS,
    });
    //2. parsedRoomsNumber = [ 'FOUR' ]

    if (Array.isArray(parsedRoomsNumber)) {
        parsedRoomsNumber = parsedRoomsNumber.map((item) => {
            return getRoomNumberByStringValue(item as RoomsNumber);
        });
        //3. parsedRoomsNumber = [ '4' ]

        return parsedRoomsNumber;
    }

    return [];
};

//BE expects different keys
export const mapSearchInvestmentKeys = ({
    areaMin,
    areaMax,
    priceMin,
    priceMax,
    roomsNumber,
}: SearchInvestmentFormTypes): AdvertInvestmentFilters => ({
    priceFrom: priceMin,
    priceTo: priceMax,
    // BE expects  ['4'] for roomsNumber instead of ['FOUR']
    numberOfRooms: parseRoomNumbers(roomsNumber),
    areaFrom: areaMin,
    areaTo: areaMax,
});

//if there are no filters -> array with one element is returned
export const getURLWithoutQueryParams = (router: NextRouter): string => router.asPath.split('?')[0];

import { getQuarterFromDate } from '@domains/ad/helpers/getQuarterFromDate';
import { useGetAdLocationAddress } from '@domains/ad/hooks/useGetAdLocationAddress';
import { AD_INFORMATION_VALUE_LABEL } from '@domains/ad/labels';
import type { Ad } from '@domains/ad/types/Ad';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBuilding } from '@fortawesome/free-regular-svg-icons/faBuilding';
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar';
import type { AdInformationLabel } from '@type/ad/adDetails';
import type { AdInformationValue } from '@type/ad/adInformationValue';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';

import { AdLocationMapLink } from '../AdLocation/AdLocationMapLink';
import drawCircle from './assets/icons-linear-draw-circle.svg?url';
import locationIcon from './assets/icons-linear-location.svg?url';
import { ProjectTopInformationBox } from './ProjectTopInformationBox';
import { BoxContent, Boxes, NumberOfUnitsInProject } from './ProjectTopInformationBoxes.theme';

interface Props {
    ad: Ad;
}

const DISPLAYED_INFORMATION_LABELS = [
    'location',
    'offered_estates_types_project',
    'project_finish_date',
    'number_of_units_in_project',
] as const;

type DisplayedInformationLabel = (typeof DISPLAYED_INFORMATION_LABELS)[number];

const ICON: Record<DisplayedInformationLabel, IconDefinition> = {
    location: locationIcon,
    offered_estates_types_project: faBuilding,
    project_finish_date: faCalendar,
    number_of_units_in_project: drawCircle,
} as const;

const TITLE: Record<DisplayedInformationLabel, string> = {
    location: 'frontend.ad.project-top-information-boxes.title-location',
    offered_estates_types_project: 'frontend.ad.project-top-information-boxes.title-offered-estates-types-project',
    project_finish_date: 'frontend.ad.project-top-information-boxes.title-finish-date',
    number_of_units_in_project: 'frontend.ad.project-top-information-boxes.title-number-of-units-in-project',
};

export const ProjectTopInformationBoxes: FC<Props> = ({ ad }) => {
    const { topInformation, location } = ad;
    const locationAddress = useGetAdLocationAddress(location);
    const [t] = useTranslations();

    const topInformationByLabel = useMemo(
        () =>
            topInformation.reduce<Record<string, AdInformationValue[] | undefined>>((accumulator, label) => {
                return {
                    ...accumulator,
                    [label.label]: label.values,
                };
            }, {}),
        [topInformation],
    );

    const getBoxContent = ({
        label,
        values,
        t,
    }: {
        label: string;
        values: AdInformationValue[];
        t: Translator;
    }): ReactNode => {
        switch (label) {
            case 'location': {
                if (!locationAddress) {
                    return null;
                }

                return <AdLocationMapLink locationAddress={locationAddress} isNewProjectPageAdLocation />;
            }
            case 'offered_estates_types_project': {
                if (values.length === 0) {
                    return null;
                }

                const translationValue = values[0];

                return <BoxContent>{t(AD_INFORMATION_VALUE_LABEL[translationValue])}</BoxContent>;
            }
            case 'project_finish_date': {
                if (values.length === 0) {
                    return null;
                }

                const formattedDate = getQuarterFromDate(values[0]);

                return (
                    <BoxContent>
                        {t('frontend.ad.top-information.date-quarter-full', {
                            quarter: formattedDate.quarter,
                            year: formattedDate.year,
                        })}
                    </BoxContent>
                );
            }
            case 'number_of_units_in_project': {
                if (values.length === 0) {
                    return null;
                }

                const postedUnits = values[0] ?? 0;
                const totalUnits = values[1];

                return (
                    <BoxContent>
                        {postedUnits}
                        {totalUnits ? <NumberOfUnitsInProject> /{totalUnits}</NumberOfUnitsInProject> : null}
                    </BoxContent>
                );
            }
            default: {
                return null;
            }
        }
    };

    const getValues = (label: AdInformationLabel): AdInformationValue[] | undefined => {
        if (label !== 'number_of_units_in_project') {
            return topInformationByLabel[label];
        }

        const numberOfAdvertsValue = topInformationByLabel['number_of_adverts']?.[0] ?? '0';
        const numberOfUnitsInProjectValue = topInformationByLabel['number_of_units_in_project'];

        if (!numberOfUnitsInProjectValue?.[0]) {
            return [numberOfAdvertsValue];
        }

        return [numberOfAdvertsValue, numberOfUnitsInProjectValue[0]];
    };

    const boxes = DISPLAYED_INFORMATION_LABELS.map((label) => {
        const values = getValues(label) || [];

        const title = t(TITLE[label]);
        const content = getBoxContent({ label, values, t });

        if (!content) {
            return null;
        }

        return (
            <ProjectTopInformationBox icon={ICON[label]} title={title} key={label}>
                {content}
            </ProjectTopInformationBox>
        );
    });

    return <Boxes>{boxes}</Boxes>;
};

import amenities from '@domains/ad/assets/icons-linear-amenities.svg?url';
import date from '@domains/ad/assets/icons-linear-calendar.svg?url';
import condition from '@domains/ad/assets/icons-linear-condition.svg?url';
import outdoor from '@domains/ad/assets/icons-linear-extra-spaces.svg?url';
import propertiesNo from '@domains/ad/assets/icons-linear-properties.svg?url';
import security from '@domains/ad/assets/icons-linear-security.svg?url';
import storageHeight from '@domains/ad/assets/icons-linear-storageh.svg?url';
import area from '@domains/shared/assets/icons-linear-area.svg?url';
import floor from '@domains/shared/assets/icons-linear-floor.svg?url';

export const ADDITIONAL_INFORMATION_ICONS = {
    project_begin_date: date,
    number_of_floors_project: floor,
    state: condition,
    area_range: area,
    number_of_units_in_project: propertiesNo,
    ceiling_height_range: storageHeight,
    project_amenities: amenities,
    extra_spaces: outdoor,
    security,
};

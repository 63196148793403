import { OpenDayNotification } from '@domains/ad/components/OpenDayNotification/OpenDayNotification';
import { DeveloperLogo } from '@domains/ad/components/ProjectPage/components/DeveloperLogo/DeveloperLogo';
import { ProjectPrice } from '@domains/ad/components/ProjectPage/components/ProjectPrice/ProjectPrice';
import { SpecialOfferBadge } from '@domains/ad/components/SpecialOfferBadge/SpecialOfferBadge';
import type { Ad } from '@domains/ad/types/Ad';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { SPECIAL_OFFER_VARIANT } from '@type/ad/specialOffer';
import type { JSX } from 'react';
import { useContext } from 'react';

import {
    Header,
    Headline,
    OpenDay,
    SpecialOfferWrapper,
    StyledSpecialOfferInfoDateBadge,
    Title,
} from './ProjectAdHeader.theme';

interface ProjectAdHeaderProps {
    ad: Ad;
}

export const ProjectAdHeader = ({ ad }: ProjectAdHeaderProps): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);

    const { title, characteristics, agency, specialOffer, openDay, price } = ad;
    const { imageUrl, name, brandingVisible, url } = agency || {};

    const isSpecialOfferDiscount = specialOffer?.__typename === SPECIAL_OFFER_VARIANT.priceDiscount;

    return (
        <Header>
            <Headline>
                <Title data-cy="adPageAdTitle">{title}</Title>
            </Headline>
            <ProjectPrice
                specialOffer={specialOffer}
                characteristics={characteristics}
                isDesktop={isDesktop}
                priceType={price?.type}
            />
            {specialOffer ? (
                <SpecialOfferWrapper>
                    <StyledSpecialOfferInfoDateBadge specialOffer={specialOffer} />
                    {!isDesktop && !isSpecialOfferDiscount ? <SpecialOfferBadge specialOffer={specialOffer} /> : null}
                </SpecialOfferWrapper>
            ) : null}
            <DeveloperLogo url={url} imageUrl={imageUrl} name={name} brandingVisible={brandingVisible} />

            <OpenDay>
                <OpenDayNotification openDay={openDay} isDateInitiallyHidden />
            </OpenDay>
        </Header>
    );
};

import { SpecialOfferInfoDateBadge } from '@domains/ad/components/SpecialOfferInfoDateBadge/SpecialOfferInfoDateBadge';
import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Header = styled.header`
    display: grid;
    grid-template-areas:
        'specialOffer'
        'headline'
        'price'
        'mobileBadge'
        'openday';
    padding-top: 32px;
    padding-bottom: 32px;

    @media (min-width: ${BREAKPOINT.sm}) {
        grid-column-gap: 12px;
        grid-template-areas:
            'specialOffer .'
            'headline logo'
            'price logo'
            'mobileBadge logo'
            'openday .';
        grid-template-columns: 1fr auto;
    }

    border-bottom: 1px solid
        ${({ theme }): CSSProperties['borderColor'] => theme.deprecated.domains.ad.projectAdHeader.header.borderColor};
`;

export const Headline = styled.div`
    display: flex;
    grid-area: headline;
    align-items: center;
`;

export const StyledSpecialOfferInfoDateBadge = styled(SpecialOfferInfoDateBadge)`
    display: flex;
    grid-area: specialOffer;
    align-items: center;

    @media (min-width: ${BREAKPOINT.md}) {
        margin-left: 0;
    }
`;

/* max-width: long string won't break without set width */
export const Title = styled.h1`
    max-width: calc(100vw - 32px);
    margin: 0;
    font-size: ${SIZE.h4};
    font-weight: ${WEIGHT.bold};
    line-height: ${LINE_HEIGHT.x1};
    word-wrap: break-word;

    @media (min-width: ${BREAKPOINT.xs}) {
        font-size: ${SIZE.h1};
    }
`;

export const OpenDay = styled.div`
    grid-area: openday;
`;

export const SpecialOfferWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 12px;
    margin-bottom: 16px;
`;

import type { JSX } from 'react';

export const CloseIcon = (): JSX.Element => {
    return (
        <svg width="20" height="20" aria-hidden="true" viewBox="0 0 20 20">
            <path
                fill="currentColor"
                d="M14.348 14.849a1.2 1.2 0 01-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 11-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 111.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 111.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 010 1.698z"
            ></path>
        </svg>
    );
};

export const ChevronIcon = (): JSX.Element => {
    return (
        <svg width="20" height="20" aria-hidden="true" viewBox="0 0 20 20">
            <path
                fill="currentColor"
                d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 01-1.576 0S4.924 9.581 4.516 9.163s-.436-1.17 0-1.615z"
            ></path>
        </svg>
    );
};

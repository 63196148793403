import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

export const ContactFormSkeleton = styled(Skeleton)`
    width: 100%;
    height: 700px;

    @media (min-width: ${BREAKPOINT.lg}) {
        height: 572px;
    }
`;

import styled from '@emotion/styled';
import { GREY_UPDATED } from '@lib/styles/partials/colors';
import { WEIGHT } from '@lib/styles/partials/typography';

export const FieldsPair = styled.div`
    display: grid;
    grid-column-gap: 8px;
    grid-template-columns: 1fr 1ch 1fr;
`;

export const FieldsPairSeparator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: '-';
        display: block;
    }
`;

export const CombinedInput = styled.div<{ hasValue: boolean }>`
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 2px;
    border-color: ${({ theme }): string => theme.deprecated.domains.search.searchForm.rangeField.base.borderColor};
    background-color: ${({ theme, hasValue }): string =>
        theme.deprecated.domains.search.searchForm.rangeField[hasValue ? 'active' : 'base'].backgroundColor};
    color: ${GREY_UPDATED.x600};

    &:hover,
    &:focus-within {
        border-color: ${({ theme }): string => theme.deprecated.domains.search.searchForm.rangeField.hover.borderColor};
    }

    &:focus-within {
        box-shadow: ${({ theme }): string => theme.deprecated.domains.search.searchForm.rangeField.focus.boxShadow};
    }

    input {
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        padding: 11px 12px;
        border: 0;
        outline: none;
        background-color: transparent;
        color: inherit;
        font-weight: ${({ hasValue }): number => (hasValue ? WEIGHT.semibold : WEIGHT.regular)};

        &::placeholder {
            opacity: 1;
        }
    }

    label {
        display: block;
        padding: 0 12px;
        font-weight: ${WEIGHT.regular};
    }
`;

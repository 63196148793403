type NonUndefined<T> = T extends undefined ? never : T;

type WithoutUndefinedKeys<Type> = {
    [Key in keyof Type]-?: WithoutUndefinedKeys<NonUndefined<Type[Key]>>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- this really should be any
export const rejectPartialObject = <TObject extends Record<string, any>>(
    object: TObject,
): WithoutUndefinedKeys<TObject> | undefined => {
    if (Object.values(object).includes(undefined)) {
        return undefined;
    }

    return object as unknown as WithoutUndefinedKeys<TObject>;
};

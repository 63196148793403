import {
    PlayButton,
    Player,
    Wrapper,
} from '@domains/ad/components/ProjectPage/components/VideoPlayer/VideoPlayer.theme';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import type { JSX } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
    videoLink: string;
    thumbnailUrl: string;
}

const YOUTUBE_THUMBNAIL_RESOLUTIONS = [
    'maxresdefault.jpg',
    'hqdefault.jpg',
    'hq720.jpg',
    'sddefault.jpg',
    'default.jpg',
];

const checkIfIsYoutubeVideo = (url: string): boolean => {
    const videoUrl = new URL(url);

    return videoUrl.hostname === 'www.youtube.com';
};

export const VideoPlayer = ({ videoLink, thumbnailUrl }: Props): JSX.Element => {
    const [isIframeDisplayed, setIsIframeDisplayed] = useState(false);
    const [ytThumbnailIndex, setYtThumbnailIndex] = useState(0);
    const [t] = useTranslations();
    const imgRef = useRef<HTMLImageElement | null>(null);

    const isYoutubeVideo = checkIfIsYoutubeVideo(videoLink);
    const thumbnailUrlWithResolution = isYoutubeVideo
        ? `${thumbnailUrl}${YOUTUBE_THUMBNAIL_RESOLUTIONS[ytThumbnailIndex]}`
        : thumbnailUrl;

    const handleClickPlay = (): void => {
        setIsIframeDisplayed(true);
    };

    const handleImageLoad = useCallback(() => {
        /* when the selected thumbnail resolution is not available the youtube api
         returns a default image which has 90x120 dimensions and by checking the
         naturalHeight we can check if we need to use a lower resolution thumbnail */
        const shouldChangeThumbnailResolution =
            isYoutubeVideo &&
            imgRef?.current?.naturalHeight === 90 &&
            ytThumbnailIndex < YOUTUBE_THUMBNAIL_RESOLUTIONS.length - 1;

        if (shouldChangeThumbnailResolution) {
            setYtThumbnailIndex((previousState) => previousState + 1);

            return;
        }

        if (ytThumbnailIndex + 1 === YOUTUBE_THUMBNAIL_RESOLUTIONS.length) {
            setIsIframeDisplayed(true);
        }
    }, [imgRef, isYoutubeVideo, ytThumbnailIndex]);

    useEffect(() => {
        if (imgRef.current?.complete) {
            handleImageLoad();
        }
    }, [handleImageLoad]);

    return (
        <Wrapper>
            <img
                src={thumbnailUrlWithResolution}
                onLoad={handleImageLoad}
                alt={t('frontend.ad.video-section.background-img-alt-text')}
                ref={imgRef}
            />
            {isIframeDisplayed ? (
                <Player src={`${videoLink}?autoplay=1`} allow="autoplay"></Player>
            ) : (
                <PlayButton icon={faPlay} size="3x" onClick={handleClickPlay} />
            )}
        </Wrapper>
    );
};

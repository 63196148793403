import { Label } from '@domains/shared/components/Label/Label';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ChangeEvent, JSX } from 'react';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { CombinedInput, FieldsPair, FieldsPairSeparator } from './RangeField.theme';

interface InputProps {
    dataCy?: string;
    name: string;
    id: string;
    label?: string;
    placeholder?: string;
    ariaLabel?: string;
}

interface Props {
    minInput: InputProps;
    maxInput: InputProps;
    onBlur?: (min: string, max: string) => void;
}

const ONLY_NUMBERS_REGEX = /\D/g;

export const RangeField = ({ minInput, maxInput, onBlur = (): void => undefined }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { register, control } = useFormContext();
    const minValue = useWatch({
        control,
        name: minInput.name,
    });
    const maxValue = useWatch({
        control,
        name: maxInput.name,
    });
    const handleOnBlur = useCallback(() => {
        onBlur(minValue, maxValue);
    }, [minValue, maxValue, onBlur]);

    const minInputRegister = register(minInput.name);
    const maxInputRegister = register(maxInput.name);

    return (
        <FieldsPair>
            <CombinedInput hasValue={!!minValue}>
                <input
                    name={minInputRegister.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                        event.target.value = event.target.value.replace(ONLY_NUMBERS_REGEX, '');
                        minInputRegister.onChange(event);
                    }}
                    ref={minInputRegister.ref}
                    id={minInput.id}
                    inputMode="decimal"
                    type="text"
                    pattern="\d*"
                    data-cy={minInput.dataCy}
                    aria-label={minInput.ariaLabel || t('frontend.search.range-field.min-field.default-label')}
                    placeholder={minInput.placeholder}
                    onBlur={handleOnBlur}
                />
                {minInput.label ? <Label htmlFor={minInput.id}>{minInput.label}</Label> : null}
            </CombinedInput>
            <FieldsPairSeparator />
            <CombinedInput hasValue={!!maxValue}>
                <input
                    name={maxInputRegister.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                        event.target.value = event.target.value.replace(ONLY_NUMBERS_REGEX, '');
                        maxInputRegister.onChange(event);
                    }}
                    ref={maxInputRegister.ref}
                    id={maxInput.id}
                    inputMode="decimal"
                    type="text"
                    pattern="\d*"
                    data-cy={maxInput.dataCy}
                    aria-label={maxInput.ariaLabel || t('frontend.search.range-field.max-field.default-label')}
                    placeholder={maxInput.placeholder}
                    onBlur={handleOnBlur}
                />
                {maxInput.label ? <Label htmlFor={maxInput.id}>{maxInput.label}</Label> : null}
            </CombinedInput>
        </FieldsPair>
    );
};

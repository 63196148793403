import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

const MOBILE_GRID_AREAS = `
    'id'
    'referenceId'
    'datePublished'
    'modifiedAt'
`;

export const Container = styled.div`
    display: grid;
    grid-template-areas: ${MOBILE_GRID_AREAS};
    padding-bottom: 16px;
    color: ${({ theme }): string => theme.deprecated.domains.ad.adMeta.color};

    @media (min-width: ${BREAKPOINT.sm}) {
        grid-template-areas: ${MOBILE_GRID_AREAS};
        grid-template-columns: 1fr 1fr;
    }
`;

export const OfferId = styled.div`
    grid-area: id;
`;

export const ReferenceId = styled.div`
    grid-area: referenceId;
`;

const BaseDate = styled.div`
    @media (min-width: ${BREAKPOINT.sm}) {
        text-align: left;
    }
`;

export const DatePublished = styled(BaseDate)`
    grid-area: datePublished;
`;

export const DateModified = styled(BaseDate)`
    grid-area: modifiedAt;
`;

import { SITE_CONFIG } from '@config/siteConfig';
import { getDescription } from '@domains/ad/components/Table/helpers/getDescription';
import type { ExtendedAdInformationWithLink } from '@domains/ad/components/Table/setAdInformationLink';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { RoomsValue } from '@type/ad/adDetails';
import type { AdvertLinks } from '@type/ad/advertLinks';
import type { ForwardRefRenderFunction, JSX } from 'react';
import { forwardRef, memo, useRef } from 'react';

import { RemoteServicesTableItem } from './RemoteServicesTableItem';
import { Container, Title } from './Table.theme';
import { getDescriptionForRooms } from './tableDescriptionFormaters';
import { TableItem } from './TableItem';
import { useElementOnResize } from './useElementOnResize';

const checkIsStringArray = (array: RoomsValue[] | string[]): array is string[] => {
    return !array.some((value) => typeof value !== 'string');
};

interface Props {
    advertLinks?: AdvertLinks;
    icons?: Record<string, string>;
    items: ExtendedAdInformationWithLink[];
    isOneColumn?: boolean;
    title?: string;
    translationPrefix: string;
    isInvestment: boolean;
    shouldRenderAskForInformation?: boolean;
    testId?: string;
    dataCy: string;
    shouldHaveNewTranslationKeys?: boolean;
}

const BaseTable: ForwardRefRenderFunction<HTMLDivElement, Props> = (
    {
        items = [],
        translationPrefix,
        title,
        isOneColumn = false,
        advertLinks,
        icons = {},
        isInvestment,
        shouldRenderAskForInformation,
        testId,
        dataCy,
        shouldHaveNewTranslationKeys = false,
    },
    ref,
): JSX.Element => {
    const { shouldUseTypologyInsteadOfNumberOfRooms } = SITE_CONFIG;

    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const tableRef = useRef<HTMLDivElement | null>(null);
    const { observeResize, unobserveResize } = useElementOnResize(isOneColumn, tableRef);

    return (
        <div ref={ref} data-testid={testId} data-cy={dataCy}>
            {title ? <Title>{title}</Title> : null}
            <Container isOneColumn={isOneColumn} ref={tableRef} hasTitle={!!title}>
                {items.map(({ label, values, unit, links }) => {
                    const icon = icons[label];
                    const title = t(`${translationPrefix}label-${label}`);
                    const isRemoteServicesItem = label === 'remote_services';

                    // RemoteServices should not be rendered in TopInfo when the new posting form is launched
                    // TODO delete the logic when the new posting form is launched
                    if (isRemoteServicesItem) {
                        const isRemoteServicesFlagActive = values.length > 0 && values[0] === 'remote_services::1';

                        return (
                            <RemoteServicesTableItem
                                key={label}
                                icon={icon}
                                advertLinks={advertLinks}
                                title={title}
                                isRemoteServicesFlagActive={isRemoteServicesFlagActive}
                                shouldRenderAskForInformation={shouldRenderAskForInformation}
                                touchPointButtonForMissingInfo={label}
                            />
                        );
                    }

                    const description = checkIsStringArray(values)
                        ? getDescription({
                              values,
                              links,
                              label,
                              unit,
                              isInvestment,
                              lang,
                              translationPrefix,
                              t,
                              shouldHaveNewTranslationKeys,
                              shouldUseTypologyInsteadOfNumberOfRooms,
                          })
                        : getDescriptionForRooms({ values, translationPrefix, t });

                    return (
                        <TableItem
                            description={description}
                            icon={icon}
                            key={label}
                            observeResize={observeResize}
                            isOneColumn={isOneColumn}
                            title={title}
                            unobserveResize={unobserveResize}
                            shouldRenderAskForInformation={shouldRenderAskForInformation}
                            label={label}
                        />
                    );
                })}
            </Container>
        </div>
    );
};

export const Table = memo(forwardRef<HTMLDivElement, Props>(BaseTable));

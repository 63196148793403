import { useCheckIsDesktop } from '@domains/shared/hooks/useCheckIsDesktop/useCheckIsDesktop';
import type { ContactFormProps, ContactFormVariant } from '@widgets/contactForm/types/contactForm';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { memo } from 'react';

import { ContactFormSkeleton } from './ContactDetails.theme';

const LazyContactFormWrapper = dynamic(() => import('@widgets/contactForm/ContactFormWrapper'), {
    loading: () => <ContactFormSkeleton />,
});

/*
 * Note: Looks like this component can be removed in future
 * it needs to be here for now, because its avoiding failing tests for AdPage
 * Probably it can be replaced when we will start using RWD Context for ad and ProjectPage
 **/

interface ContactDetailsProps extends ContactFormProps {
    afterContactForm?: JSX.Element | null;
    beforeContactForm?: JSX.Element | null;
    contactFormVariant?: ContactFormVariant;
}

const ContactDetailsBase = ({
    contactFormVariant = 'default',
    ...restProps
}: ContactDetailsProps): JSX.Element | null => {
    const isDesktop = useCheckIsDesktop();

    if (isDesktop) {
        return <LazyContactFormWrapper variant={contactFormVariant} {...restProps} />;
    }

    return null;
};

export const ContactDetails = memo(ContactDetailsBase);

import { AdInvestmentSearch } from '@domains/ad/components/AdInvestmentUnits/AdInvestmentSearch/AdInvestmentSearch';
import {
    checkIfNumber,
    getURLWithoutQueryParams,
    mapSearchInvestmentKeys,
    resetEmptyStringValueToNull,
} from '@domains/ad/components/AdInvestmentUnits/AdInvestmentSearch/helpers/getValuesForSearchInvestment';
import { DEFAULT_INVESTMENT_UNITS_LOOKUP, DEFAULT_PROJECT_UNITS_LOOKUP } from '@domains/ad/consts/adInvestmentUnits';
import { getIdForLocationType } from '@domains/ad/helpers/getIdForLocationType';
import type { Ad } from '@domains/ad/types/Ad';
import type { Lookup, SearchInvestmentFormTypes } from '@domains/ad/types/adInvestmentUnits';
import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { createSearchParams } from '@domains/shared/helpers/createSearchParams';
import { getRoomNumberByStringValue } from '@domains/shared/helpers/getRoomNumberByTheString';
import { parseParameter } from '@domains/shared/helpers/parseParameter';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import { ROOMS_NUMBER_VARIANTS } from '@type/search/filters/roomsNumber';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { ForwardRefRenderFunction, JSX } from 'react';
import { forwardRef, useCallback, useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AdSectionHeading } from '../AdSectionHeading/AdSectionHeading';
import { AdInvestmentUnitsSection, NewProjectPageAdSectionHeading } from './AdInvestmentUnits.theme';

const LazyData = dynamic(
    () => import('@domains/ad/components/AdInvestmentUnitsDataContainer/AdInvestmentUnitsDataContainer'),
    { loading: () => <Skeleton height={100} /> },
);

interface Props {
    ad: Ad;
    isNewProjectPage?: boolean;
}

const FORM_DEFAULT_VALUE: SearchInvestmentFormTypes = {
    areaMin: null,
    areaMax: null,
    priceMin: null,
    priceMax: null,
    roomsNumber: null,
};

const AdInvestmentUnitsBase: ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { ad, isNewProjectPage }: Props,
    ref,
): JSX.Element => {
    const containerRef = useRef<HTMLElement>(null);
    const [shouldShowData, setShouldShowData] = useState<boolean>(false);
    const [t] = useTranslations();
    const router = useRouter();
    const urlWithoutQueryParams = getURLWithoutQueryParams(router);
    const { isDesktop } = useContext(RWDContext);

    useIntersection(containerRef, () => {
        setShouldShowData(true);
    });

    const investmentLookupWithFilters: Lookup = {
        ...(isNewProjectPage ? DEFAULT_PROJECT_UNITS_LOOKUP : DEFAULT_INVESTMENT_UNITS_LOOKUP),
        filters: mapSearchInvestmentKeys(router.query as SearchInvestmentFormTypes),
    };

    const [lookup, setLookup] = useState<Lookup>({ ...investmentLookupWithFilters, page: 0 });

    const { trackEvent } = useTracking();

    const runTracking = useCallback(
        (params: SearchInvestmentFormTypes): void => {
            const { areaMax, areaMin, priceMax, priceMin, roomsNumber } = params;

            trackEvent('search', {
                selected_region_id: getIdForLocationType(ad.location, 'province'),
                selected_subregion_id: getIdForLocationType(ad.location, 'county'),
                selected_city_id: getIdForLocationType(ad.location, 'city'),
                selected_district_id: getIdForLocationType(ad.location, 'district'),
                selected_street_id: getIdForLocationType(ad.location, 'street'),
                selected_location_id: getIdForLocationType(ad.location, 'location'),
                business: 'sell',
                market: 'primary',
                from_price: priceMin,
                to_price: priceMax,
                from_surface: areaMin,
                to_surface: areaMax,
                nb_rooms: Array.isArray(roomsNumber)
                    ? (roomsNumber as RoomsNumber[]).map(getRoomNumberByStringValue)
                    : [],
                touch_point_page: 'project_page',
                touch_point_button: 'search_button',
                re_seller_type: 'developer',
            });
        },
        [ad, trackEvent],
    );

    const {
        areaMin: areaMinFromQuery,
        areaMax: areaMaxFromQuery,
        priceMin: priceMinFromQuery,
        priceMax: priceMaxFromQuery,
        roomsNumber: roomsNumberFromQuery,
    } = router.query;

    const parsedRoomsNumber = parseParameter(roomsNumberFromQuery, {
        multipleValueVariants: ROOMS_NUMBER_VARIANTS,
    });

    const formMethods = useForm({
        defaultValues: {
            areaMin: checkIfNumber(areaMinFromQuery as string) ? areaMinFromQuery : FORM_DEFAULT_VALUE.areaMin,
            areaMax: checkIfNumber(areaMaxFromQuery as string) ? areaMaxFromQuery : FORM_DEFAULT_VALUE.areaMax,
            priceMin: checkIfNumber(priceMinFromQuery as string) ? priceMinFromQuery : FORM_DEFAULT_VALUE.priceMin,
            priceMax: checkIfNumber(priceMaxFromQuery as string) ? priceMaxFromQuery : FORM_DEFAULT_VALUE.priceMax,
            roomsNumber: parsedRoomsNumber,
        },
    });

    const { reset: resetForm, getValues: getFormValues } = formMethods;

    const onFormSubmit = useCallback((): void => {
        const params = getFormValues();

        runTracking(params as SearchInvestmentFormTypes);

        resetEmptyStringValueToNull(params);

        const searchQueryParams = createSearchParams(params).toString();

        if (searchQueryParams.length > 0) {
            //overwrite previous filters
            router.push(`${urlWithoutQueryParams}?${searchQueryParams}`, undefined, { shallow: true });
        } else {
            //restart url to default
            router.push(urlWithoutQueryParams, undefined, { shallow: true });
        }

        //update lookup -> causing useQuery re-run
        setLookup((previousLookupValues) => {
            return {
                ...previousLookupValues,
                filters: mapSearchInvestmentKeys(params as SearchInvestmentFormTypes),
                page: 1,
            };
        });
    }, [urlWithoutQueryParams, runTracking, getFormValues, router]);

    const clearSearchCriteria = useCallback((): void => {
        resetForm(FORM_DEFAULT_VALUE);
        trackEvent('clear_filters', {
            touch_point_page: 'project_page',
            touch_point_button: 'searchbox',
        });
    }, [resetForm, trackEvent]);

    const noResultsButtonClickCallback = (): void => {
        clearSearchCriteria();
        setLookup((previousLookupValues) => {
            return {
                ...previousLookupValues,
                filters: {},
            };
        });

        router.push(urlWithoutQueryParams, undefined, { shallow: true });
    };

    const updateLookup = (lookup: Lookup): void => {
        setLookup(lookup);
    };

    return (
        <div ref={ref}>
            {isNewProjectPage ? (
                <NewProjectPageAdSectionHeading isBorderless>
                    {t('frontend.projectpage.investment-units.title')}
                </NewProjectPageAdSectionHeading>
            ) : null}
            {isDesktop ? (
                <AdInvestmentSearch
                    clearSearchCriteria={clearSearchCriteria}
                    formMethods={formMethods}
                    onFormSubmit={onFormSubmit}
                    isNewProjectPage={isNewProjectPage}
                />
            ) : null}
            <AdInvestmentUnitsSection ref={containerRef}>
                {isNewProjectPage ? null : (
                    <AdSectionHeading>{t('frontend.ad.investment-units.title')}</AdSectionHeading>
                )}
                {shouldShowData ? (
                    <LazyData
                        ad={ad}
                        lookup={lookup}
                        updateLookup={updateLookup}
                        buttonClickCallback={noResultsButtonClickCallback}
                        isNewProjectPage={isNewProjectPage}
                    />
                ) : null}
            </AdInvestmentUnitsSection>
        </div>
    );
};

export const AdInvestmentUnits = forwardRef<HTMLDivElement, Props>(AdInvestmentUnitsBase);

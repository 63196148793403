import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { FC, PropsWithChildren } from 'react';

import { Content, StyledIcon, Title } from './ProjectTopInformationBox.theme';

interface Props extends PropsWithChildren {
    icon: string | IconDefinition;
    title: string;
}

export const ProjectTopInformationBox: FC<Props> = ({ icon, title, children }) => {
    return (
        <Content>
            {typeof icon === 'string' ? <img alt="" src={icon} /> : <StyledIcon icon={icon} />}
            <Title>{title}</Title>
            {children}
        </Content>
    );
};
